import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Chart from "../../components/page/Chart";
import gameJson from '../../gameRange.json';

function Bidding(props) {
  const { socket, logout, joinGame, setJoinGame, tradeWs } = props.data;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const { slug } = useParams();
  const navigate = useNavigate();
  const [game, setGame] = useState({});
  const [endTime, setEndTime] = useState('');
  const [unitVal, setUnitVal] = useState([0, 0, 0, 0, 0, 0]);
  const [unitValSum, setUnitValSum] = useState(0);
  const [investBtc, setInvestBtc] = useState(0);
  const [btcPrice, setBtcPrice] = useState([]);
  const [btcRange, setBtcRange] = useState([]);
  const [remainingTime, setRemainingTime] = useState('');
  const [timerColor, setTimerColor] = useState('info');
  const [potAmount, setPotAmount] = useState(0);
  const [potRange, setPotRange] = useState([]);

  const fetchGame = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/game/${slug}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) { logout(); }
    const json = await response.json();
    if (json.status === "success") {
      setGame(json.data.game);
      setEndTime(new Date(dateFormat(json.data.game.end_time)));
      setPotAmount(json.data.game.pot_amount);
      setPotRange(JSON.parse(json.data.game.range));
      if (!joinGame.includes(json.data.game.slug)) {
        socket.emit("join_game", json.data.game.slug);
        joinGame[json.data.game.position] = json.data.game.slug;
        setJoinGame(joinGame);
      }
    }
  };

  useEffect(() => { fetchGame(); }, [slug]);// eslint-disable-next-line
  useEffect(() => { socket.on("updatePotAmount", (data) => { setPotAmount(data.pot_amount); }); }, [socket]);

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const wagerClick = (position, unit) => {
    const checkPrice = btcPrice;
    let check = true;

    checkPrice.forEach((price) => {
      if (price.includes('')) {
        check = false;
      }
    })

    if (btcRange == '') {
      toast.warning(
        <h5 className="font-weight-bold">Please select a range</h5>,
        {
          position: "bottom-center",
          autoClose: 5000,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return false;
    }

    var newArr = [];
    newArr[position] = unit;
    setUnitVal(newArr);
  }

  const removeUnit = (position, unit) => {
    var newArr = [];
    newArr[0] = 0;
    setUnitVal(newArr);
  }

  useEffect(() => {
    var sum = unitVal.reduce((a, b) => a + b, 0);
    setUnitValSum(sum);
    var newBtc = (unitValSum * 5) / 27087.12;
    setInvestBtc(newBtc.toFixed(5));
  }, [unitVal, unitValSum]);

  const submitBid = async () => {
    if (unitValSum === 0) {
      Swal.fire('please select at least one unit');
      return false;
    }

    if (user.wallet < (unitValSum * 5)) {
      Swal.fire("you don't have enough balance to place bid.");
      return false;
    }

    let newBtcPrice = [];
    btcPrice.forEach((element, index) => {
      newBtcPrice[index] = element.join('') * 1;
    });
    Swal.fire({
      iconHtml: `<img src="${process.env.REACT_APP_HOST}/assets/images/logo/logo.png" width="100" height=100>`,
      customClass: {
        icon: 'no-border'
      },
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, Let's Play"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/bid/add`, {
          method: "POST",
          headers: { "Content-Type": "application/json", "auth-token": token },
          body: JSON.stringify({
            gid: game.gid,
            btc_range: btcRange,
            // btc_price: JSON.stringify(newBtcPrice),
            unit: unitValSum,
            amount: (unitValSum * 5)
          })
        }).then(async (response) => {
          if (response.status === 401) {
            logout();
          } else {
            const json = await response.json();
            if (json.status === "success") {
              Swal.fire('Success!', 'Your bid is successfully submitted.', 'success');
              user.wallet = json.data.user.wallet;
              sessionStorage.setItem('user', JSON.stringify(user));
              navigate("/dashboard");
            }
          }
        }).catch((error) => console.log(error));
      }
    })
  }
  const changePickRange = (value) => { setBtcRange(value); }

  useEffect(() => {
    const endTime = new Date(game.end_time);
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = endTime - now;
      if (distance < 0) {
        clearInterval(interval);
        fetchGame();
      } else {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimerColor(hours === 0 && minutes === 0 && seconds <= 9 ? 'danger' : 'info');
        setRemainingTime(`${hours <= 9 ? "0" : ""}${hours} : ${(minutes >= 0 && minutes <= 9 ? '0' : '') + minutes} : ${(seconds >= 0 && seconds <= 9 ? '0' : '') + seconds}`);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return (
    <div className="app-content center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <div className="container">
            <div className="row justify-content-between mb-1">
              <div className="col-lg-6 pr-xl-3">
                <div className="card card-body round">
                  <div className="d-flex justify-content-between pb-1" style={{ borderBottom: "2px dashed gray" }}>
                    <div className="my-auto">
                      <h3 className="font-large-1">{game.name}</h3>
                      <p className="font-medium-3">#{game.gid}</p>
                    </div>
                    <div className="my-auto">
                      <h6 className={`${timerColor} font-medium-3`}>{remainingTime}</h6>
                      <h6 className="font-medium-3">hh : mm : ss</h6>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="font-medium-3 py-1">Predict the bitcoin price at the draw time</h3>
                    <div className="row">
                      <div className="col">
                        <p className="font-medium-3 mb-0">Start Time </p>
                        <p className="font-medium-3 mb-0">{game.start_time && dateFormat(game.start_time)}</p>
                      </div>
                      <div className="col">
                        <p className="font-medium-3 mb-0">Close Time </p>
                        <p className="font-medium-3 mb-0">{game.end_time && dateFormat(game.end_time)}</p>
                      </div>
                      <div className="col">
                        <p className="font-medium-3 mb-0">Draw Time </p>
                        <p className="font-medium-3 mb-0">{game.draw_time && dateFormat(game.draw_time)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-xl-3">
                <div className="card card-body round">
                  <Chart data={{ trade: tradeWs, liveShow: true }} />
                </div>
              </div>
            </div>
            <section className="mb-3">
              <h4 className="gray font-large-1 headingBorder"><b>IT's TIME TO PLAY <span className={timerColor}>({remainingTime})</span></b></h4>
            </section>
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <h3 className="font-weight-bold text-center mb-2">Step 1 of 3</h3>
                <div className="card biddingCard">
                  <div className="card-header p-1" style={{ backgroundColor: game.colour }}>
                    <div className="card-title font-weight-bold text-center white " style={{ fontSize: "24px" }}>Pick a Range</div>
                  </div>
                  <div className="card-body px-0 py-2">
                    <div className="row p-2">
                      {
                        game.name && gameJson[game.name].map((i, index) => {
                          return (
                            <React.Fragment key={i.value}>
                              <div onClick={() => changePickRange(i.label)} className={`px-2 py-1 text-white text-center rounded ml-2 col-md-5 font-medium-2 font-bolder mb-3`} style={{ backgroundColor: `${btcRange == i.label ? "#05b576" : game.colour}`, cursor: "pointer" }}>
                                <span className="range-label">{i.label}</span>
                                <b>{i.value}</b>
                                <span className="range-amt">${potRange[i.label] ?? 0}</span>
                                <span className="range-qus" title="Range pot amount" data-toggle="tooltip" data-placement="right">?</span>
                              </div>
                            </React.Fragment>
                          )
                        })
                      }
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h3 className="font-weight-bold text-center mb-2">Step 2 of 3</h3>
                <div className="card biddingCard">
                  <div className="card-header p-1" style={{ backgroundColor: game.colour }}>
                    <div className="card-title font-weight-bold text-center white" style={{ fontSize: "24px" }}>Select Your Wager</div>
                  </div>
                  <div className="card-body" style={{ padding: "20px 30px 20px 30px" }}>
                    <div className="row justify-content-center white font-medium-3 text-center">
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[0] > 0 ? "unit-1-active" : "unit-1"}`}>
                          {unitVal[0] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(0, 1) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(0, 1) }}><b>1<br />Unit<br />$5</b></div>
                          <div onClick={() => { wagerClick(0, 1) }}>
                            <b className="font-weight-bold">x</b>{unitVal[0] ?? 0}
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[1] > 0 ? "unit-2-active" : "unit-2"}`}>
                          {unitVal[1] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(1, 2) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(1, 2) }}><b>2<br />Unit<br />$10</b></div>
                          <div onClick={() => { wagerClick(1, 2) }}>
                            <b className="font-weight-bold">x</b>{unitVal[1] ?? 0}
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[2] > 0 ? "unit-5-active" : "unit-5"}`}>
                          {unitVal[2] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(2, 5) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(2, 5) }}><b>5<br />Unit<br />$25</b></div>
                          <div onClick={() => { wagerClick(2, 5) }}>
                            <b className="font-weight-bold">x</b>{unitVal[2] ?? 0}
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[3] > 0 ? "unit-10-active" : "unit-10"}`}>
                          {unitVal[3] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(3, 10) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(3, 10) }}><b>10<br />Unit<br />$50</b></div>
                          <div onClick={() => { wagerClick(3, 10) }}>
                            <b className="font-weight-bold">x</b>{unitVal[3] ?? 0}
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[4] > 0 ? "unit-20-active" : "unit-20"}`}>
                          {unitVal[4] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(4, 20) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(4, 20) }}><b>20<br />Unit<br />$100</b></div>
                          <div onClick={() => { wagerClick(4, 20) }}>
                            <b className="font-weight-bold">x</b>{unitVal[4] ?? 0}
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 p-1`} style={{ padding: "5px" }}>
                        <div className={`${unitVal[5] > 0 ? "unit-100-active" : "unit-100"}`}>
                          {unitVal[5] > 0 && <span className="badge badge-pill bg-white danger badge-down badge-glow font-weight-bold" onClick={() => { removeUnit(5, 100) }}>-</span>}
                          <div className="wager" onClick={() => { wagerClick(5, 100) }}><b>100<br />Unit<br />$500</b></div>
                          <div onClick={() => { wagerClick(5, 100) }}>
                            <b className="font-weight-bold">x</b>{unitVal[5] ?? 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h3 className="font-weight-bold text-center mb-2">Step 3 of 3</h3>
                <div className="card biddingCard">
                  <div className="card-header p-1" style={{ backgroundColor: game.colour }}>
                    <div className="card-title font-weight-bold text-center white " style={{ fontSize: "24px" }}>Place Your Bid</div>
                  </div>
                  <div className="card-body">
                    <h4 className="font-weight-bold font-large-1 mb-0">Your Bid</h4>
                    <hr className="mt-0" />
                    <div className="tableCard">
                      <div className="px-1">
                        <h4 className="font-medium-3 font-weight-bold mb-0">BTC</h4>
                        <h5 className="font-medium-2 mb-0">{investBtc}</h5>
                      </div>
                      <div className="px-1">
                        <h4 className="font-medium-3 font-weight-bold mb-0">Unit</h4>
                        <h5 className="font-medium-2 text-center mb-0">{unitValSum}</h5>
                      </div>
                      <div className="px-1">
                        <h4 className="font-medium-3 font-weight-bold mb-0">Amount</h4>
                        <h5 className="font-medium-2 text-center mb-0">${unitValSum * 5}</h5>
                      </div>
                    </div>
                    <hr />
                    <h4 className="mb-0 text-center font-medium-1">Amount will be deducted from your wallet</h4>
                    <hr />
                    <h4 className="mb-0 font-medium-3"><b>Available Balance : </b>${user.wallet}</h4>
                    <h4 className="mb-0 font-medium-3 mt-1"><b style={{ verticalAlign: "4px" }}>Pot Amount : </b><span className="font-large-1 white px-1" style={{ fontWeight: "600", backgroundColor: game.colour, borderRadius: "1.5em" }}>${potAmount}</span></h4>
                    <div className="text-center">
                      <button role="button" onClick={submitBid} className="btn btn-info round mt-1 font-medium-3" disabled={unitValSum === 0 || user.wallet < (unitValSum * 5) ? true : false}>Let's Play</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Bidding;
