import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';

export default function Profile(props) {
  const { update, logout } = props.data;
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [profile, setProfile] = useState('');
  const token = sessionStorage.getItem("token");

  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  });

  const fetchUser = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/user`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setUser(json.data.user);
        sessionStorage.setItem("user", JSON.stringify(json.data.user));
      }
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const updateUser = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/user/update`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "auth-token": token },
      body:JSON.stringify(user)
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        Toast.fire({
          icon: 'success',
          title: "User details updated successfully",
        });
        update(json.data.user);
        sessionStorage.setItem("user", JSON.stringify(json.data.user));
      }
    }
  }

  const handleChange = (e, field) => {    
    let newArr = {...user};
    newArr[field] = e.target.value;
    setUser(newArr);
  }

  const handlePhoto = (e) => {
    const imageOutput = document.getElementById('imageOutput');
    if (e.target.files[0]) {
      imageOutput.src = URL.createObjectURL(e.target.files[0]);
      setProfile(e.target.files[0]);
    }else{
      imageOutput.src = process.env.REACT_APP_API_HOST+"/images/"+user.profile;
      setProfile('');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();    
    if (profile === '') return false;

    const formData = new FormData();
    formData.append('photo', profile);
  
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/user/profile`, {
      method: 'POST',
      headers: { "auth-token": token },
      body: formData
    });

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        Toast.fire({
          icon: 'success',
          title: "User profile pic updated successfully",
        });
        update(json.data.user);
        sessionStorage.setItem("user", JSON.stringify(json.data.user));
      }
    }
  }

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="page-account-settings">
            <div className="row justify-content-center">
              <div className="col-xl-9">
                <section className="mb-3">
                  <h4 className="gray font-large-1 headingBorder"><b>My Profile</b></h4>            
                </section>
                <div className="card card-body round p-3 mb-3">
                  <form onSubmit={handleSubmit} encType='multipart/form-data' id="profileUpload">
                    <div className="row mb-2">
                      <div className="col-sm-4 mb-2 mb-sm-0">
                        <img src={`${process.env.REACT_APP_API_HOST}/images/${user.profile}`} className="round img-fluid" id="imageOutput" alt="profile"/>
                      </div>
                      <div className="col-sm-8 my-auto">
                        <div className="col-12 px-0 mb-1">
                          <input type="file" className="font-medium-2" accept=".png, .jpg, .jpeg" name="photo" onChange={handlePhoto} id="account-upload"/>
                          <p className="font-medium-1 black text-muted mb-0">Allowed JPG, PNG Max size of 1 MB.</p>
                        </div>
                        <button type="submit" className="btn round btn-info font-medium-2" htmlFor="account-upload">Upload photo</button>                        
                      </div>
                    </div>                    
                  </form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <div className="controls">
                          <label className="font-weight-bold font-medium-2" htmlFor="account-name">Name</label>
                          <input type="text" className="form-control font-medium-2 round" id="account-name" placeholder="Enter Name" value={user.name} onChange={(e) => handleChange(e,"name")}/>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <div className="controls">
                          <label className="font-weight-bold font-medium-2" htmlFor="account-email">Email Address</label>
                          <input type="email" className="form-control font-medium-2 round" id="account-email" placeholder="Enter email address" value={user.email} onChange={(e) => handleChange(e,"email")}/>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <div className="controls">
                          <label className="font-weight-bold font-medium-2" htmlFor="account-payment-address">Payment Address</label>
                          <input type="text" className="form-control font-medium-2 round" id="account-payment-address" placeholder="Enter Bitcoin address" value={user.payment_address ?? ''} onChange={(e) => handleChange(e,"payment_address")}/>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex flex-sm-row flex-column justify-content-start mt-1">
                      <button type="button" onClick={updateUser} className="btn btn-info font-medium-2 mr-1 round">Update Profile</button>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
