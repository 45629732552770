import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import io from "socket.io-client";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Index from "./pages/Index";
import Dashboard from "./pages/Dashboard";
import Test from "./pages/Test";
import Profile from "./pages/Profile";
import Bidding from "./pages/game/Bidding";
import Wallet from "./pages/Wallet";
import MyBids from "./pages/MyBids";
import { useState } from "react";
import Toster from "./components/Toster";
const socket = io.connect(process.env.REACT_APP_API_HOST);
const tradeWs = new WebSocket("wss://ws.coincap.io/prices?assets=bitcoin");

function App() {
  const location = useLocation();
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const auth = token ? true : false;  
  sessionStorage.setItem("isLogin", auth);
  const [isLogin, setIsLogin] = useState(auth);
  const [joinGame, setJoinGame] = useState([]);
  const [joinUser, setJoinUser] = useState(true);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));  
  const logout = () => {
    sessionStorage.clear();
    setIsLogin(false);
    sessionStorage.setItem("isLogin", false);
  };

  return (
    <>
      {location.pathname === "/" &&
        <Routes>
          <Route exact path="/" element={<Index data={{isLogin}} />} />          
        </Routes>
      }

      {isLogin === true && location.pathname !== '/' && (
        <>
          <Header data={{ user, logout }} />
          <Routes>
            <Route exact path="/dashboard" element={<Dashboard data={{ tradeWs, socket, joinGame, setJoinGame, logout }} />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/profile" element={<Profile data={{ update: setUser, logout }} />} />
            <Route exact path="/bidding/:slug" element={<Bidding data={{ tradeWs, socket, joinGame, setJoinGame, logout }} />} />
            <Route exact path="/wallet" element={<Wallet auth={logout} />} />
            <Route exact path="/mybids" element={<MyBids auth={logout} />} />
            <Route exact path="*" element={<Navigate replace to={"/dashboard"} />} />
          </Routes>
          <Footer/>
        </>
      )}

      <Toster data={{ socket, user, isLogin, joinUser, setJoinUser }} />
      {isLogin === false && (location.pathname !== '/' || location.pathname === '*') && (
        <>
          <Routes>
            <Route exact path="/login" element={<Login data={{ setUser, setToken, setIsLogin }} />} />
            <Route exact path="/register" element={<Register data={{ setUser, setToken, setIsLogin }} />} />
            <Route exact path="*" element={<Navigate replace to={"/"} />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
