import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toster(props) {
  const { socket, user, joinUser, setJoinUser } = props.data;
  useEffect(() => {
    if(joinUser && user != null){
      console.log('user joined games');
      socket.emit("join_user", user._id);
      setJoinUser(false);
    }
  }, []);

  useEffect(() => {
    console.log('Socket Conneted');
    socket.on("newGameStart", (data) => {
      let localIsLogin = sessionStorage.getItem("isLogin");
      if (!document.hidden && localIsLogin === "true") {
        toast.info(
          <h5 className="font-weight-bold">
            <Link to={`/bidding/${data.slug}`}>{`${data.name} game started - Let's play`}</Link>
          </h5>,
          {
            position: "top-left",
            autoClose: 5000,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );        
      }
    });

    socket.on("gameResult", (data) => {
      const option = {
        position: "top-left",
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      };
      let localIsLogin = sessionStorage.getItem("isLogin");
      if (localIsLogin === "true") {
        if (data.status === "win") {
          let fire = document.getElementById("pyro");
          fire.style.display = "block";
          setTimeout(() => {
            let fire = document.getElementById("pyro");
            fire.style.display = "none";  
          }, 5000);
          toast.success(
            <>
              <h5 className="font-weight-bold">{`#${data.game} Game result`}</h5>
              <h5>Congratulations !! You Won the Game</h5>
            </>,
            option
          );
        } else {
          toast.error(
            <>
              <h5 className="font-weight-bold">{`#${data.game} Game result`}</h5>
              <h5>Sorry, better luck next time</h5>
            </>,
            option
          );
        }        
      }
    });

    return () => {
      console.log('Socket Disconneted');
    }
  }, [socket]);

  return (
    <ToastContainer />    
  );
}

export default Toster;
