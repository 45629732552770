import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import Pagination from '../components/page/Pagination';
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

export default function Wallet(props) {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const walletLimit = process.env.REACT_APP_WALLET_LIMIT;
  const [userTran, setUserTran] = useState({});
  const [tranInfo, setTranInfo] = useState({});
  const [addMoney, setAddMoney] = useState('');
  const [getPaymentAddress, setGetPaymentAddress] = useState('');
  const [withdrawMoney, setWithdrawMoney] = useState('');
  const [pagination, setPagination] = useState('');
  const paymentAddress = user.payment_address;
  
  const fetchTran = async (pageId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/wallet?page=${pageId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setUserTran(json.data.transactions);
        setPagination(json.data.pagination);
        let userInfo = JSON.parse(sessionStorage.getItem("user"));
        userInfo.wallet = json.data.user.wallet;
        sessionStorage.setItem("user", JSON.stringify(userInfo));
      }
    }
  };

  useEffect(() => {
    // setPaymentAddress();
    setTimeout(() => {
      fetchTran(1);      
    }, 500);
    // eslint-disable-next-line
  },[]);

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'});
  };

  const handleTranInfo = async (tranId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/wallet/transaction/${tranId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setTranInfo(json.data.transaction);
      }
    }
  };

  const addMoneyWallet = async () => {
    document.getElementById("addMoneyBtn").disabled = true;
    let addMoneyAlert = document.getElementById('add-money-alert');
    if(addMoney <= 0) {
      document.getElementById('add-money').classList.add('is-invalid');
      addMoneyAlert.classList.remove('d-none');
      addMoneyAlert.innerHTML = "Please enter the valid amount.";
      return false;
    }

    let limit = walletLimit - user.wallet;
    if(addMoney > limit){
      document.getElementById("addMoneyBtn").disabled = false;
      document.getElementById('add-money').classList.add('is-invalid');
      addMoneyAlert.classList.remove('d-none');
      addMoneyAlert.innerHTML = `You can't add more than ${limit}`;
      if(limit<=0){
        addMoneyAlert.innerHTML = `You have reach limit amount ${walletLimit}`;
      }
      return false;
    }

    // hide error if displayed.
    document.getElementById('add-money').classList.remove('is-invalid');
    addMoneyAlert.classList.add("d-none");

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/wallet/add`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "auth-token": token },
      body:JSON.stringify({
        "amount":addMoney
      })
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setGetPaymentAddress(json.data.transaction.payment_address);
        document.getElementById('addModal').click();
        document.getElementById('paymentAddressBtn').click();
      }
    }
    fetchTran(1);
  }

  const copyText = () => {
    navigator.clipboard.writeText(getPaymentAddress);
    Swal.mixin({
      toast: true,
      icon: 'success',
      title: 'Payment address copied !',
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire();  
  }

  const withdrawMoneyWallet = async () => {
    document.getElementById("withdrawMoneyBtn").disabled = true;
    let withdrawMoneyAlert = document.getElementById('withdraw-money-alert');
    if(withdrawMoney <= 0) {
      document.getElementById('withdraw-money').classList.add('is-invalid');
      withdrawMoneyAlert.classList.remove('d-none');
      withdrawMoneyAlert.innerHTML = "Please enter the valid amount.";
      return false;
    }
    
    if(withdrawMoney > user.wallet){ // Todo : add withdraw limit condition
      document.getElementById('withdraw-money').classList.add('is-invalid');
      withdrawMoneyAlert.classList.remove('d-none');
      withdrawMoneyAlert.innerHTML = `You can't withdraw more than ${user.wallet}`;
      return false;
    }

    // hide error if displayed.
    document.getElementById('withdraw-money').classList.remove('is-invalid');
    withdrawMoneyAlert.classList.add("d-none");

    // Todo: Configure the wallet api to generate tran id.

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/wallet/withdraw`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "auth-token": token },
      body:JSON.stringify({
        "amount":withdrawMoney * 1,
        // "payment_address":paymentAddress
      })
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {        
        Swal.fire('Success!', 'Amount withdraw successfully', 'success');
        fetchTran(1);
        document.getElementById("withdrawMoney").click();
        document.getElementById("withdrawMoneyBtn").disabled = false;
        setWithdrawMoney(0);
      }else{
        document.getElementById('withdraw-money').classList.add('is-invalid');
        withdrawMoneyAlert.classList.remove('d-none');
        withdrawMoneyAlert.innerHTML = json.errors;
      }
    }
  }

  return (
    <div className="app-content container center-layout">
      <div className="content-wrapper">
        <div className="content-body">
          <section>
            <div className="row">
              <div className="col-xl-5 col-md-12">
                <div className={`card round card-content ${paymentAddress == null ? 'mt-3 mb-1' : 'my-3'}`}>
                  <div className="media align-items-stretch text-white round bg-wallet">
                    <div className="media-right bg-dark-wallet p-2 media-middle round-r my-auto">
                      <i className="icon-wallet font-large-2 text-white"></i>
                    </div>
                    <div className="media-body p-1 my-auto">
                      <h4 className="text-white font-weight-bold font-medium-3">Available Balance</h4>
                      <h2 className="text-white mb-0">${user.wallet}</h2>
                    </div>
                    <div className="media-right p-1 media-middle">
                      <div><button className="bg-dark-wallet btn btn-block white round font-weight-bold mb-1" data-toggle="modal" data-target="#addMoney">Add</button></div>
                      <div><button className="bg-dark-wallet btn btn-block white round font-weight-bold mt-1" data-toggle="modal" data-target="#withdrawMoney">Withdraw</button></div>
                      <div style={{display:"none"}}>
                        <button data-toggle="modal" data-target="#paymentAddress" id="paymentAddressBtn"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {paymentAddress == null && <>
            <div className="alert bg-info alert-icon-left alert-arrow-left alert-dismissible round mb-3" role="alert">
              <span className="alert-icon"><i className="la la-info-circle"></i></span>                                                  
              To withdraw balance, please update your payment bitcoin address by <Link to={"/profile"} className="alert-link">clicking here</Link>.            
            </div>
          </>}
          <section className="mb-3">
            <h4 className="gray font-large-1 headingBorder"><b>Transaction List</b></h4>
            <div className="my-3">
              {userTran.length > 0 &&
                userTran.map((tran) => {
                  return (                  
                    <div className="card card-body round mb-1" key={tran._id}>
                      <div className="tableCard">
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Date</h4>
                          <h5 className="mb-0">{dateFormat(tran.created_at)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Status</h4>
                          <h5 className={`round badge badge-${tran.status === 'success' ? 'success' : 'danger'} font-medium-2 ucfirst mb-0`}>{tran.status}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Amount</h4>
                          <h5 className={`${tran.type === 'win' || tran.type === 'Add' ? 'success' : 'danger' } mb-0`}>${tran.amount}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Type</h4>
                          <h5 className={`${tran.type === 'win' || tran.type === 'Add' ? 'success' : 'danger' } ucfirst mb-0`}>{tran.type}</h5>
                        </div> 
                        <div className="my-auto">                          
                          <button className="btn btn-sm round btn-info font-medium-1" onClick={() => handleTranInfo(tran._id)} data-toggle="modal" data-target="#bidInfoModal">More info</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {!userTran.length && 
                <div className="card card-body round mb-1">
                  <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
                </div>
              }
              <div className="mb-3 mt-2">
                <Pagination data={{ pagination, fetchTran }} />
              </div>
            </div>
          </section>

          <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 black font-weight-bold">Transaction Info</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="fa-regular fa-rectangle-xmark"></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="table-responsive">
                    <table className="table mb-0 table-bordered">
                      <tbody className="font-medium-3">
                        <tr>
                          <td><b>Date Time</b></td>
                          <td>{dateFormat(tranInfo.created_at)}</td>
                        </tr>
                        <tr>
                          <td><b>Tran Id</b></td>
                          <td>{tranInfo.tran_id}</td>
                        </tr>
                        <tr>
                          <td><b>Status</b></td>
                          <td><span className={`round badge badge-${tranInfo.status === 'success' ? 'success' : 'danger'} font-medium-2 ucfirst`}>{tranInfo.status}</span></td>
                        </tr>
                        <tr>
                          <td><b>Amount</b></td>
                          <td><span className={`${tranInfo.type === 'win' || tranInfo.type === 'Add' ? 'success' : 'danger' }`}>${tranInfo.amount}</span></td>
                        </tr>
                        <tr>
                          <td><b>Type</b></td>
                          <td className="ucfirst">{tranInfo.type}</td>
                        </tr>
                        {
                          tranInfo.bid_id && 
                          <tr>
                            <td><b>Bid Id</b></td>
                            <td>{tranInfo.bid_id}</td>
                          </tr>
                        }
                        <tr>
                          <td><b>Note</b></td>
                          <td className="ucfirst">{tranInfo.note}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal animated zoomIn" id="addMoney" tabIndex="-1" aria-labelledby="addWalletMoney" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Add Money</h5>
                  <button type="button" className="close" id="addModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa-regular fa-rectangle-xmark"></i></span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="controls">
                    <label className="font-medium-3 font-weight-bold" htmlFor="add-money">Amount <span className="font-small-3">(USD)</span></label>
                    <input type="number" min={0} className="form-control font-medium-3 round" id="add-money" placeholder="Enter amount" value={addMoney} onChange={(e) => setAddMoney(parseInt(e.target.value))}/>
                    <div id="add-money-alert" className="invalid-feedback font-medium-1 d-none"></div>
                  </div>
                  <div className="text-center">
                    <button type="button"  onClick={addMoneyWallet} id="addMoneyBtn" className="btn btn-info font-medium-2 btn-block round mt-1">Add Money</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal animated zoomIn" id="withdrawMoney" tabIndex="-1" aria-labelledby="withdrawMoney" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Withdraw Money</h5>
                  <button type="button" className="close" id="withdrawModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa-regular fa-rectangle-xmark"></i></span>
                  </button>
                </div>
                <div className="modal-body p-2">                  
                  <div className="controls">
                    <label className="font-medium-3 font-weight-bold" htmlFor="withdraw-money">Amount <span className="font-small-3">(USD)</span></label>
                    <input type="number" min={0} className="form-control font-medium-3 round" id="withdraw-money" placeholder="Enter amount" value={withdrawMoney} onChange={(e) => setWithdrawMoney(e.target.value)}/>
                    <div id="withdraw-money-alert" className="invalid-feedback font-medium-1 d-none"></div>
                  </div>
                  <button type="button" onClick={withdrawMoneyWallet} id="withdrawMoneyBtn" className="btn btn-info round font-medium-2 btn-block mt-1">Withdraw Money</button>                  
                </div>
              </div>
            </div>
          </div>
          <div className="modal animated zoomIn" id="paymentAddress" tabIndex="-1" aria-labelledby="paymentAddress" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Payment Address</h5>
                  <button type="button" className="close" id="paymentAddress" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa-regular fa-rectangle-xmark"></i></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <QRCode title="GeeksForGeeks" value={getPaymentAddress}/>
                    <div className="input-group my-2">
                      <input type="text" className="form-control font-medium-3 round" value={getPaymentAddress} disabled/>
                      <div className="input-group-append" onClick={() => copyText()}>
                        <span className="input-group-text round"><i className="fa-regular fa-copy"></i></span>
                      </div>
                    </div>
                    <p className="black font-weight-bold mt-1"></p>
                    <h5 className="mt-2">
                      Please use this payment address to initiate transaction from your crypto wallet.<br/>Your amount will be deposited in about 5-30 mins.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}