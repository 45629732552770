import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Hearder(props) {
  useEffect(() => {
    let bodyTag = document.body;
    bodyTag.style.backgroundColor= "#f4f5fa";
    if (bodyTag.classList.contains("1-column")) bodyTag.classList.remove("1-column");
    if (bodyTag.classList.contains("bg-full-screen-image")) bodyTag.classList.remove("bg-full-screen-image");
    if (bodyTag.classList.contains("blank-page")) bodyTag.classList.remove("blank-page");
    if (bodyTag.dataset.col === "1-column") bodyTag.dataset.col = "2-column";
    if (!bodyTag.classList.contains("2-columns")) bodyTag.classList.add("2-columns");
  }, []);
  const { user, logout } = props.data;
  
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light d-md-none shadow-sm">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <img className="brand-logo" alt="play-crypto" src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} width={50} />
          </Link>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link font-large-1 text-center" to={"/wallet"}>
                <p className="mb-0"><i className="icon-wallet"></i></p>
                <p className="mb-0 font-medium-3">${user.wallet}</p>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <nav className="header-navbar navbar-expand-md navbar navbar-without-dd-arrow navbar-light navbar-brand-center d-none d-md-block">
        <div className="navbar-wrapper font-medium-3">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row mx-auto">
              <li className="nav-item">
                <Link className="navbar-brand" to={"/"} style={{padding:"7px"}}>
                  <img className="brand-logo dash-logo" alt="play-crypto" src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} />
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-container container center-layout">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left">
                <li className="nav-item">
                  <Link className="nav-link font-medium-3" to={"/dashboard"}>
                    <i className="la la-home font-medium-3"></i> Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle font-medium-3" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="la la-gamepad font-medium-3"></i>Games
                  </a>
                  <div className="dropdown-menu p-0">
                    <Link className="dropdown-item font-medium-3" to={"/bidding/three_mins"}>3 Min Game</Link>
                    <Link className="dropdown-item font-medium-3" to={"/bidding/five_mins"}>5 Min Game</Link>
                    <Link className="dropdown-item font-medium-3" to={"/bidding/fifteen_mins"}>15 Min Game</Link>
                    <Link className="dropdown-item font-medium-3" to={"/bidding/twenty_four_hours"}>Mega Game</Link>
                  </div>
                </li>
              </ul>
              <ul className="nav navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link font-medium-3" to={"/wallet"}><i className="icon-wallet"></i> Wallet</Link>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <a className="dropdown-toggle nav-link dropdown-user-link" data-toggle="dropdown" href="#">
                    <span className="mr-1 font-medium-3 user-name text-bold-600" style={{marginTop:"7px"}}>{user.name}</span>
                    <img src={`${process.env.REACT_APP_API_HOST}/images/${user.profile}`} alt="avatar" className="avatar" width={50}/>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right p-0">
                    <Link className="dropdown-item font-medium-3" to={"/profile"}>
                      <i className="ft-user"></i>Edit Profile
                    </Link>
                    <Link className="dropdown-item font-medium-3" to={"/mybids"}>
                      <i className="la la-gamepad"></i>My Bids
                    </Link>
                    <div className="dropdown-divider m-0"></div>
                    <Link className="dropdown-item font-medium-3" role="button" onClick={logout}>
                      <i className="ft-power"></i>Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="pyro" id="pyro" style={{display:"none"}}>
        <div className="before"></div>
        <div className="after"></div>
      </div>
    </>
  );
}

export default Hearder;
