import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/Style.css";

export default function Index(props) {
  const { isLogin } = props.data;
  useEffect(() => {
    let bodyTag = document.body;
    if (bodyTag.classList.contains("1-column"))
      bodyTag.classList.remove("1-column");
    if (bodyTag.classList.contains("bg-full-screen-image"))
      bodyTag.classList.remove("bg-full-screen-image");
    if (bodyTag.classList.contains("blank-page"))
      bodyTag.classList.remove("blank-page");
    if (bodyTag.dataset.col === "1-column") bodyTag.dataset.col = "2-column";
    if (!bodyTag.classList.contains("2-columns"))
      bodyTag.classList.add("2-columns");
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <img src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} alt="logo" width={50}/>
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-1">
                <p data-toggle="modal" data-target="#terms" className="nav-link font-medium-3 mb-0" style={{ cursor: "pointer" }}>
                  Terms of use
                </p>
              </li>
              <li className="nav-item mx-1">
                <p data-toggle="modal" data-target="#policy" className="nav-link font-medium-3 mb-0" style={{ cursor: "pointer" }}>
                  Privacy Policy
                </p>
              </li>
              <li className="nav-item mx-1">
                <Link to="mailto:admin@play-crypto.com" className="nav-link font-medium-3 mb-0" style={{ cursor: "pointer" }}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="app-content center-layout home-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="backgroundImage"></div>
            <section id="hero" className="hero d-flex py-4 align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 hero-img order-lg-2 text-center">
                    <img src="assets/img/bitcoin2.png" className="img-fluid animated zoomIn" alt="logo"/>
                  </div>
                  <div className="col-lg-6 my-auto text-lg-left text-center order-lg-1">
                    <h1>Welcome to play-crypto</h1>
                    <h3 className="mt-2">
                      - Predict the future… price of Bitcoin.
                    </h3>
                    <h3 className="mt-1">- Make your prediction</h3>
                    <h3 className="mt-1">- Choose any or all bet wagers</h3>
                    <h3 className="mt-1">- Match the price of live Bitcoin</h3>
                    <h3 className="mt-1">- Winner take all</h3>
                    <div className="mt-1">
                      <div className="mt-2">
                        <Link to={isLogin ? "/dashboard" : "/login"} className="btn btn-info round font-medium-3 text-bold-300 mb-3 mb-lg-0">
                          <span>Let's Play</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>            
          </div>
        </div>
      </div>
      <div className="modal" id="terms" tabIndex="-1" aria-labelledby="terms" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content round">
            <div className="modal-header">
              <h5 className="modal-title font-medium-3 font-weight-bold">Terms of use</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <i className="fa-regular fa-rectangle-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                By using the Play-Crypto website at{" "}
                <Link to={"/"}>play-crypto.com</Link>(the “Site”), its
                subdomains, and any services provided by the site (collectively
                the “Services”), you agree to be bound by these Terms of Use
                (the “Terms”) as well as our Privacy Policy. If you do not agree
                to the Terms, do not use the Site or our Services. The Site and
                Services are provided by Play-Crypto (hereinafter “Play-Crypto”
                or the “Company” or “us”), an Estonian Private Limited Liability
                Company with registration number 16029997, and YOU (hereinafter
                “User” or “you”), with respect to your use of the Services
                offered through the Play-Crypto platform (hereinafter
                “Platform”).
              </p>
              <h5 className="font-weight-bold">
                THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION. ALL DISPUTES
                ARISING UNDER THIS USER AGREEMENT SHALL BE SETTLED IN BINDING
                ARBITRATION. ENTERING INTO THIS AGREEMENT CONSTITUTES A WAIVER
                OF YOUR RIGHT TO A TRIAL BY JURY AND PARTICIPATION IN A CLASS
                ACTION LAWSUIT.
              </h5>
              <h5 className="font-weight-bold">APPLICABILITY</h5>
              <p>
                By using our services, you agree to be bound by the Terms in its
                latest version. You are aware that Play-Crypto may change these
                Terms at any time, on our sole discretion without any prior
                notice to you. Any changes will be effective immediately upon
                posting on our Website. Your continued use of Play-Crypto means
                that you accept any new or modified terms.
              </p>
              <h5 className="font-weight-bold">‍ACCESSING THE SERVICES</h5>
              <p>
                Play-Crypto grants you a revocable, non-exclusive,
                non-transferable, limited license to use the Services
                (information or software) on the Site in accordance with these
                Terms of Use and applicable Service Terms. This is the grant of
                a license, not a transfer of title, and under this license, you
                may not:
              </p>
              <p>
                modify or copy the materials;
                <br />
                attempt to decompile or reverse engineer any software contained
                on the Site; remove any copyright or other proprietary notations
                from the materials; or transfer the materials to another person
                or “mirror” the materials on any other server.
              </p>
              <p>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by Play-Crypto at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </p>
              <p>
                You must keep secret all credentials associated with the
                Services. You are solely responsible for managing and
                maintaining the security of any information relating to such
                credentials and agree that Play-Crypto shall not be held
                responsible for any unauthorized access to the Services or any
                resulting harm you may suffer.
              </p>
              <h5 className="font-weight-bold">DISCLAIMER</h5>
              <p>
                Play-Crypto DISCLAIMS ANY AND ALL PROMISES, REPRESENTATIONS AND
                WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE, DATA ACCURACY, SYSTEM INTEGRATION,
                TITLE, NON-INFRINGEMENT AND/OR QUIET ENJOYMENT, AND ANY SERVICES
                PROVIDED BY CHAIN GAMES ARE PROVIDED “AS IS” AND “AS AVAILABLE.”
                EXCEPT AS EXPRESSLY PROVIDED HEREIN, YOU ACKNOWLEDGE THAT CHAIN
                GAMES MAKES NO WARRANTIES UNDER THIS AGREEMENT DIRECTLY FOR THE
                BENEFIT OF ANY END USER, AND THAT CHAIN GAMES'S OBLIGATIONS
                UNDER THIS AGREEMENT ARE FOR THE BENEFIT OF YOU ONLY, AND NOT
                FOR THE BENEFIT OF ANY OTHER PERSON. IN ENTERING INTO THIS
                AGREEMENT, YOU REPRESENT THAT YOU HAVE NOT RELIED UPON ANY
                REPRESENTATION OR WARRANTY OF CHAIN GAMES OR ITS AFFILIATES
                EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT. WE DO NOT MAKE
                ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SERVICES
                SHALL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE.
              </p>
              <p>
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the Site will be free
                of viruses or other destructive code. You are responsible for
                implementing sufficient procedures and checkpoints to satisfy
                your particular requirements for anti-virus protection and
                accuracy of data input and output, and for maintaining a means
                external to our site for any reconstruction of any lost data.
              </p>
              <h5 className="font-weight-bold">SERVICES</h5>
              <p>
                Play-Crypto is a blockchain gaming platform for wagering on peer
                to peer games of skill. The Play-Crypto UTILITY TOKEN is the
                in-game currency for use within the Play-Crypto decentralized
                application.
              </p>
              <p>
                Company gives no warranties that Play-Crypto will be
                exchangeable on specific platforms or that they shall remain
                exchangeable on exchange platforms or that they would be
                exchangeable on other exchanges.
              </p>
              <p>
                Play-Crypto is not a financial institution and is currently not
                under supervision of any financial supervisory authority.
                Play-Crypto does not provide any licensed financial services,
                such as investment services, fund management or investment
                advice. None of the information or analysis presented herein are
                intended to form the basis for any investment decision, no
                specific recommendations are intended, and Play-Crypto, website
                and Play-Crypto do not offer, are not and shall not be construed
                as investment or financial products. In the event of any
                regulatory changes would impact Play-Crypto, reserves the right
                to amend its Services, the Site and Play-Crypto
              </p>
              <p>
                Play-Crypto is not an investment, a security, nor an investment
                product and any action, notice, communication, message,
                decision, managerial act, or omission of the mentioned shall not
                be understood and interpreted as such.
              </p>
              <p>
                Company gives no guarantees as to the value of any of the
                Play-Crypto and explicitly warns users that there is no reason
                to believe that Play-Crypto will increase in value, and they
                might also decrease in value or lose their value entirely.
              </p>
              <p>
                You represent that you acquire Play-Crypto exclusively for your
                use in our Services, and not for investment or financial
                purposes. You also represent and warrant that you do not
                consider Play-Crypto as a security and you understand that
                Play-Crypto may lose all of their value and that you are aware
                of all other risks deriving from holding Play-Crypto as
                specified in these Terms and you accept them.
              </p>
              <h5 className="font-weight-bold">ELIGIBILITY</h5>

              <p>
                To use any of the Services that involve wagers, you must qualify
                and agree to the conditions set forth below. Failure to qualify
                and continuously abide by any of the following conditions
                constitutes a breach of this Agreement. Specifically, you agree
                that:
              </p>
              <p>
                You are a natural person, at least 18 years old, or the age of
                legal consent for engaging in Skill Challenges under the laws of
                any jurisdiction that applies to you, whichever is greater. We
                expressly reserve the right to request proof of age, at any
                time;
              </p>
              <p>
                You have not been placed on any of the sanctions lists,
                published and maintained by the United Nations, European Union,
                any EU country, UK Treasury and US Office of Foreign Assets
                Control (OFAC).
              </p>
              <p>
                You have verified and determined that your use of the Site
                Services does not violate any law or regulation in any
                jurisdiction that applies to you. It is your sole responsibility
                to ensure that this is the case;
              </p>
              <p>
                You fully understand the methods, rules and procedures of the
                Site and Services, and your use of the Site and Services will
                always comply fully with these Terms and our Privacy Policy.
              </p>
              <p>
                You will not engage in any conduct that injures or may injure
                the business, reputation or goodwill of the Company;
              </p>
              <p>
                You are fully aware that there is a risk of losing money by
                using the Services to arrange, enter, and/or participate in our
                Services and that you are solely responsible for any such loss;
              </p>
              <p>
                You possess the legal authority to enter into this Agreement
                including the authority to accept all of these Terms;
              </p>
              <p>
                You shall not infringe or encroach upon Chain Games' or any
                third party's personal, contractual or proprietary rights,
                including, without limitation, patents, trademarks, copyrights,
                rights of privacy or publicity or trade secrets;
              </p>
              <p>
                You are physically located in a U.S. state or a jurisdiction
                located outside the U.S. in which participation in the Skill
                Challenges is not prohibited by the law of that State or
                jurisdiction;
              </p>
              <p>
                You understand that Chain Games may monitor your Internet access
                location, without creating an obligation to do so, and may use
                techniques which are intended to block or restrict access from a
                jurisdiction in which participation in the Services are illegal
                or restricted;
              </p>
              <p>
                You will not mask your identify in any way, including without
                limitation, IP masking by accessing the Site Services over any
                type of Proxy Server, through IP masking software or the like;
                and
              </p>
              <p>
                We DO NOT warrant that our Site and/or Services are in
                compliance with all the laws and regulations in any localities,
                including your locality. It is your responsibility to ensure
                compliance with the laws and regulations of your locale. Please
                be certain to use the Services only after you have determined in
                good-faith that they are in compliance with state, local and
                other governing laws and regulations.
              </p>

              <h5 className="font-weight-bold">RISKS</h5>
              <h5 className="font-weight-bold">
                Development Failure or Abortion
              </h5>
              <p>
                Acquiring and storing Play-Crypto for purposes of using our
                services involves various risks, in particular that Play-Crypto
                may not be able to launch some operations and continue
                developing its platform or community. Any interested person who
                is not in the position to accept nor to understand the risks
                associated with the activity, or any other risks as indicated in
                the Terms, should not acquire Play-Crypto or use our Services.
              </p>
              <h5 className="font-weight-bold">
                Legal Risk Concerning Security Regulation
              </h5>
              <p>
                There is a risk that in some jurisdictions Play-Crypto might be
                considered as a security, now or in the future. Company does not
                give warranties or guarantees that Play-Crypto are not a
                security in all jurisdictions. Each user of Play-Crypto shall
                bear its own legal or financial consequences of Play-Crypto
                being considered a security in their respective jurisdiction.
                The legal ability of Company to provide Play-Crypto in some
                jurisdictions may be eliminated by future regulation or legal
                actions. In the event, it turns out with a high degree of
                certainty that Play-Crypto is not legal in certain jurisdiction,
                Company will base on its sole discretion either (a) cease
                operations in that jurisdiction, or (b) adjust Play-Crypto in a
                way to comply with the regulation should that be possible and
                viable. It is your obligation to check if acquisition and
                disposal of Play-Crypto is legal in your jurisdiction, and by
                accepting these Terms you expressly agree and warrant that you
                will not use Play-Crypto should the use not be legal in the
                relevant jurisdiction.
              </p>
              <h5 className="font-weight-bold">
                Risk of Malfunction of Blockchain
              </h5>
              <p>
                Nobody can guarantee the Ethereum source code used by Company
                will be flaw-free. It may contain certain flaws, errors, defects
                and bugs, which may disable some functionality for users, expose
                users' information or otherwise. Such flaw would compromise the
                usability and/or security of Company and consequently bring
                adverse impact on the value of Company. Open-source codes rely
                on transparency to promote community-sourced identification and
                solution of problems within the code.{" "}
                <b>Update of the Source Code</b>. The Ethereum source code could
                be updated, amended, altered or modified from time to time by
                the developers and/or the community of Ethereum. Nobody is able
                to foresee or guarantee the precise result of such update,
                amendment, alteration or modification. As a result, any update,
                amendment, alteration or modification could lead to an
                unexpected or unintended outcome that adversely affects
                Play-Crypto and/or Company operation or market value.{" "}
                <b>Internet Transmission Risks</b>. You acknowledge that there
                are risks associated with utilizing Internet-based digital
                assets, products and website including, but not limited to, the
                failure of hardware, software, and Internet connections. You
                acknowledge that the Company shall not be responsible for any
                communication failures, disruptions, errors, distortions or
                delays you may experience when using our services, website,
                platform, Play-Crypto howsoever caused.
              </p>
              <h5 className="font-weight-bold">Cryptography Risks</h5>
              <p>
                You acknowledge that there are risks associated with
                cryptography, such as code cracking or technical advances such
                as the development of quantum computers, could present risks to
                all crypto-currencies including Play-Crypto. This could result
                in the theft, loss, disappearance, destruction or devaluation of
                Play-Crypto. To a reasonable extent, Company will be prepared to
                take proactive or remedial steps to update the protocol
                underlying Company in response to any advances in cryptography
                and to incorporate additional reasonable security measures where
                appropriate. It is impossible to predict the future of
                cryptography or the future of security innovations to an extent
                that would permit the Company to accurately guide the
                development of Company to take into account such unforeseeable
                changes in the domains of cryptography or security.
              </p>
              <h5 className="font-weight-bold">Splitting and Forking</h5>
              <p>
                Ethereum is an open source project and supported by the
                community. The developers of the Company do not lead the
                development, marketing, operation or otherwise of Ethereum.
                Anybody may develop a patch or upgrade of the source code of
                Ethereum's source without prior authorization of anyone else.
                The acceptance of Ethereum patches or upgrades by a significant,
                but not overwhelming, percentage of the users could result in a
                “split” or “fork” in the blockchain of Ethereum, and
                consequently the operation of two separate networks and will
                remain separate until the split or forked blockchains are
                merged. The temporary or permanent existence of split or forked
                blockchains could adversely impact the operation and the market
                value of Play-Crypto and in the worst-case scenario, could ruin
                the sustainability of the Company. While such a split or fork in
                the blockchain of Ethereum would possibly be resolved by
                community-led efforts to merge the split or forked blockchains,
                the success is not guaranteed and could take long period of time
                to achieve.
              </p>
              <h5 className="font-weight-bold">
                Risk of Unfavorable Regulatory Action in One or More
                Jurisdictions
              </h5>
              <p>
                Blockchain technologies have been the subject of scrutiny by
                various regulatory bodies around the world. Play-Crypto could be
                impacted by one or more regulatory inquiries or actions,
                including but not limited to restrictions on the use or
                possession of Play-Crypto, which could impede or limit their
                existence, permissibility of their use and possession, and their
                value.
              </p>
              <h5 className="font-weight-bold">Risk of Theft and Hacking</h5>
              <p>
                Hackers or other groups or organizations may attempt to
                interfere with your third-party wallet, the Website or the
                availability of Play-Crypto in any number of ways, including
                without limitation denial of service attacks, Sybil attacks,
                spoofing, smurfing, malware attacks, or consensus-based attacks.
              </p>
              <h5 className="font-weight-bold">Risk of Mining Attacks</h5>
              <p>
                Ethereum blockchain, which is used for the Play-Crypto, is
                susceptible to mining attacks, including but not limited to
                double-spend attacks, majority mining power attacks,
                “selfish-mining” attacks, and race condition attacks. Mining
                Attacks, as described above, may also target other blockchain
                networks, with which Play-Crypto interacts with. Any successful
                attacks present a risk to the Play-Crypto.
              </p>
              <h5 className="font-weight-bold">
                Risk of Loss of Value and Uninsured Losses
              </h5>
              <p>
                Value of Play-Crypto may fluctuate and you may suffer loss in
                value of such acquired Play-Crypto. In addition to loss of value
                risk, Play-Crypto are entirely uninsured and are unlike bank
                accounts or accounts at some other financial institutions.
              </p>
              <h5 className="font-weight-bold">Unanticipated Risks</h5>
              <p>
                Cryptocurrencies and blockchain technologies are new and
                untested technology and in addition to the risks outlined in
                these Terms, there are also unforeseeable risks that may
                materialize as unanticipated. You acknowledge, agree and warrant
                that you have been warned of the potential risks involved by
                using our services, website and product and other relevant
                technologies mentioned herein as well as that there may be other
                risks involved, which are not specified herein and that you
                fully accept such risks. <b>KNOWLEDGE REQUIRED</b> The User
                undertaking to use the Services expressly acknowledges and
                represents that she/he has carefully reviewed the Terms and
                fully understands the risks, costs and benefits associated with
                use of the Services. The User undertaking to use our Services
                must ensure that she/he understands and has significant
                experience of cryptocurrencies, blockchain systems and services,
                and that she/he fully understands the risks associated with the
                Token Sale as well as the mechanism related to the use and
                custody of cryptocurrencies. Play-Crypto shall not be
                responsible for any loss of Play-Crypto or situations making it
                impossible to access the Play-Crypto, which may result in any
                actions or omissions of the future User or any person
                undertaking to use the Services. Play-Crypto is not a provider
                of (a) exchange services between virtual currency and fiat
                currency; (b) wallet or custodial services of credentials
                necessary to access virtual currencies or (c) cryptocurrency
                mining services. User expressly agrees and acknowledges that
                anytime throughout the duration of these Terms, Play-Crypto may
                require material, technical, commercial or any other changes and
                understands that an upgrade or modification of Play-Crypto may
                be required. If a user decides not to participate in such
                upgrade, the user acknowledges and agrees that non-upgraded
                CHAIN may lose their functionality in full and that they may no
                longer use Play-Crypto.
              </p>
              <h5 className="font-weight-bold">GAMING PLATFORM</h5>
              <p>
                <b>User Responsibility.</b>
              </p>
              <p>
                By using the Play-Crypto gaming platform, the User agrees to the
                Terms and in particular, they represent and warrant the
                following: User is solely responsible for confirming and
                ensuring their compliance with any local prohibitions and/or
                regulations that may be applicable to their activities on the
                website, including those of your country or jurisdiction of
                residence. User acknowledges underage gambling is illegal and is
                twenty-one (21) years of age or more, or above the minimum legal
                age of majority in their jurisdiction, whichever is higher. User
                further confirms that they are legally allowed to participate in
                the games and services under the relevant laws in their
                jurisdiction. User participation in the games and services is
                for purposes of recreation and entertainment only, and is
                strictly personal and not in any professional capacity. User
                participates in the games and services only on behalf of the
                member himself or herself. All information received from the
                user is true and accurate. The user will immediately notify
                Play-Crypto of any changes to the information provided. User is
                solely responsible for any and all taxes or other amounts
                payable in their resident country or jurisdiction relating to
                any winnings or withdrawals made by the user. Member takes on
                the risk of losing funds by participating in the games and
                services. Fraud, collusion, fixing or any other actions which
                can be considered unlawful are strictly forbidden in relation to
                the user's or any third-party's participation in the games and
                services. In the event such activities are taking place,
                Play-Crypto reserves the right to close the member account and
                invalidate any betting. User will only use wallet addresses that
                are valid and legally under the user's custody and control. User
                is solely responsible to keep their login details and crypto
                wallets secure and ensure that they are not disclosed to anyone.
                Play-Crypto is not responsible for any misuse of wallets. User
                is solely responsible for their own account transactions,
                wagers, bets, and engagement on the platform. User will review
                and confirm wagers and bets for any mistakes before entering the
                games and services. User acknowledges once a transaction is
                complete it cannot be changed.
              </p>
              <p>
                <b>Malicious Use</b>
              </p>
              <p>
                Users shall not perform any of the following acts in connection
                with the use of the services provided by the company: When
                applying for use or changing member information, use other
                person's information or enter false information;
                <br />
                <br />
                The act of impersonating executives, employees, operators, and
                other related parties of the company;
                <br />
                <br />
                Changing the information posted by the company;
                <br />
                <br />
                Unauthorized collection, storage, posting, or dissemination of
                other users' personal information;
                <br />
                <br />
                The act of producing, distributing, using, or advertising
                computer programs or devices or devices not provided or approved
                by the company; Intentionally sending, posting, distributing or
                using viruses, computer codes, files, programs, etc. designed to
                interfere with or destruct normal operation of information,
                computer programs, or computer software/hardware or regular
                communications equipment;
                <br />
                <br />
                Changing game services, adding or inserting other programs into
                game services, hacking, reverse-engineering servers, leaking or
                changing source code or data, establishing separate servers, or
                arbitrarily changing or stealing parts of the website without
                being granted special rights by the company; The act of using
                game services for sales, advertising, public relations,
                political activities, election campaigns, etc. without the
                consent of the company;
                <br />
                <br />
                Unauthorized reproduction, distribution, promotion, or
                commercial use of information obtained using the company's
                services, and the use of services by exploiting known or unknown
                bugs; The act of profiting from harming others in connection
                with the use of the company's services; Violating the
                intellectual property or portrait rights of the company or
                others, defaming or damaging others; Other acts contrary to good
                customs and other social norms; or Using the service using an
                automatic script.
              </p>
              <h5 className="font-weight-bold">Legal Compliance</h5>
              <p>
                You are subject to, and are solely responsible for obeying all
                laws of the state, province and/or country in which You reside
                and from which You access the Site, Log-In to the Site or
                participate in any game service. You agree to only participate
                in the game services only after You have determined in good
                faith that You are in compliance with state, local and other
                governing laws and regulations. Participation within the United
                States of America Participation in fee-based tournaments for
                prizes is prohibited in the following U.S. states, without
                limitation: Arizona. The foregoing list shall not be construed
                to imply or suggest that your participation in game services
                from an unlisted state is legal under any applicable laws or
                regulations. VOID WHERE PROHIBITED OR RESTRICTED BY LAW.
                <br />
                <br />
                Participation outside the United States of America Participation
                in fee-based tournaments for prizes may be prohibited in your
                jurisdiction and it is your sole responsibility to ensure
                compliance with such laws. VOID WHERE PROHIBITED OR RESTRICTED
                BY LAW.
                <br />
                <br />
                You agree to hold Play-Crypto harmless from any liability such
                that Play-Crypto cannot be held liable if laws applicable to you
                restrict or prohibit your participation in any game service or
                contests arranged and established through the Platform or
                otherwise. Play-Crypto makes no representations or warranties,
                implicit or explicit, as to your legal right to participate in
                any game service facilitated nor shall any person affiliated, or
                claiming affiliation with Play-Crypto, have authority to make
                such representations or warranties.
              </p>
              <h5 className="font-weight-bold">Refunds and Payouts</h5>
              <p>
                Under no circumstances will the Play-Crypto be refunded. The
                company will not refund Play-Crypto losses incurred due to user
                negligence and lack of knowledge of service usage of the
                service. Play-Crypto payouts are automatically distributed
                through smart contracts. The company is not responsible for any
                payment delays caused by delayed transactions on the blockchain
                network. Change of Service. Users may use the game service
                provided by the company in accordance with these terms of
                services, policies, and game rules established by the company.
                The contents that the company provides to its users through game
                services have comprehensive authority over the production,
                modification, maintenance, and repair of the contents according
                to the company's needs. Play-Crypto can change the service
                according to operational or technical needs to provide smooth
                game services at the company's discretion.
              </p>
              <h5 className="font-weight-bold">Collection of Information</h5>
              <p>
                Play-Crypto may store the contents and records of games and
                services on the platform. This information will be shared with
                the user community through leaderboards and statistical
                information. The company can collect and utilize the user's
                device information (setup, specification, operating system,
                version, etc.) for smooth and stable operation of the service
                and improvement of the service quality. Play-Crypto may request
                additional information from the users for the purpose of
                improving the service and introducing services to users. The
                company has the right to check the service use of the user at
                any time. If the user's malicious use is identified during such
                verification, the service can be restricted or the account in
                use can be closed.
              </p>
              <h5 className="font-weight-bold">Advertisements</h5>
              <p>
                Play-Crypto may place advertisements within the game service and
                platform. Advertisement information can also be sent by e-mail,
                text message service (LMS/SMS), push notification, etc. only to
                users who agree to receive advertisement information. The user
                may refuse to receive the advertisement information at any time.
              </p>
              <h5 className="font-weight-bold">
                ACCURACY OF INFORMATION AND THIRD-PARTY CONTENT
              </h5>
              <p>
                We endeavor to verify the accuracy of any information displayed,
                supplied, passing through or originating from the Services, but
                such information may not always be accurate or current.
                Accordingly, you should independently verify all information
                before relying on it, and any decisions or actions taken based
                upon such information are your sole responsibility. We make no
                representation or warranty of any kind, express or implied,
                statutory, or otherwise, regarding the contents of the Services,
                information and functions made accessible through the Services,
                any hyperlinks to third party websites, or the security
                associated with the transmission of information through the
                Services, or any website linked to the Services. In using the
                Services, you may view content or services provided by third
                parties, including links to web pages and services of such
                parties (“Third Party Content”). We do not control, endorse, or
                adopt any Third Party Content and have no responsibility for
                Third Party Content, including, without limitation, material
                that may be misleading, incomplete, erroneous, offensive,
                indecent, or otherwise objectionable in your jurisdiction. In
                addition, your dealings or correspondence with such third
                parties are solely between you and the third party. We are not
                responsible or liable for any loss or damage of any sort
                incurred as a result of any such dealings and your use of Third
                Party Content is at your own risk.
              </p>
              <h5 className="font-weight-bold">
                LINKS TO THIRD-PARTY WEBSITES
              </h5>
              <p>
                Our services, website, platform, Play-Crypto may include links
                to third party websites, third party wallet providers or other
                service providers (“Third Party Sites”). If you use any links
                made available through our services, website, platform,
                application to reach other websites not maintained by the
                company you will leave our services, website, platform,
                application. The linked sites are not under the control of the
                company and company is not responsible for the content of any
                linked site or any link contained in a linked site. The linked
                sites are governed by the terms and conditions of that site.
                Company shall not be responsible or liable, either directly or
                indirectly, for any damage or loss caused or alleged to be
                caused in connection with your use or reliance on any such
                content, information, statements, representations, advertising,
                products, services, or other materials available on or through
                third party sites. Company provides these links to you only as a
                convenience and the inclusion of any link does not imply
                recommendation, approval or endorsement by company of
                third-party sites, nor does it imply that the linked third-party
                sites recommends, approves of, or endorses Company.
              </p>
              <h5 className="font-weight-bold">INTELLECTUAL PROPERTY RIGHTS</h5>
              <p>
                To the extent that copyright or other intellectual property
                rights exist on the Play-Crypto platform, website, Play-Crypto
                and services such as software, know-how, analysis or programs,
                those copyrights and other intellectual and industrial rights
                belong to Play-Crypto or third-party affiliates. Under no
                circumstances will these Terms be construed as granting, by
                implication, estoppel or otherwise, a license to any
                Intellectual Property or components thereof other than as
                specifically granted in these terms. You agree that the
                Intellectual Property may be used only as provided in these
                terms. All rights not expressly granted herein are reserved.
              </p>
              <h5 className="font-weight-bold">DISCLAIMER OF WARRANTIES</h5>
              <p>
                To the fullest extent permissible under applicable law, our
                service, website, Play-Crypto are provided on an “as is,” and
                “as available” basis, without warranties of any kind, express,
                implied or statutory, including implied warranties of condition,
                uninterrupted use, merchantability, satisfactory quality,
                fitness for a particular purpose, non-infringement of
                third-party rights, and warranties (if any) arising from a
                course of dealing, usage, or trade practice, is given in
                conjunction to website, services, platform, Play-Crypto, any
                software or product contained in website, Play-Crypto and
                services and in general. Company does not warrant that the
                service, Play-Crypto token or website will be error-free or
                uninterrupted or that any defects will be corrected in
                reasonable time. You acknowledge that your use of the service,
                platform and website is at your sole risk. While every effort is
                made to ensure that information contained in the website or
                Play-Crypto is correct, Company disclaims all warranties
                regarding the accuracy, adequacy, correctness, completeness,
                reliability, timeliness, non-infringement, title,
                merchantability or fitness of any information on the Website
                and/or Play-Crypto. Company does not guarantee the
                confidentiality or privacy of any communication or information
                transmitted through services, on the Website or Play-Crypto or
                any site linked to the website. We will not be liable for the
                privacy or security of information, e-mail addresses,
                registration and any identification information (if applicable),
                disk space, communications, confidential or proprietary
                information, or any other content transmitted to us in any
                manner, or otherwise connected with your use of our services,
                website or platform. Company shall not bear any liability,
                whatsoever, for any damage or interruptions caused by any
                computer viruses, spyware, scareware, trojan horses, worms or
                other malware that may affect your computer or other equipment,
                or any phishing, spoofing or other attack. We advise the regular
                use of a reputable and readily available virus screening and
                prevention software. You should also be aware that communication
                services are vulnerable to spoofing and phishing attacks and
                should use care in reviewing messages purporting to originate
                from the Company and/or users on the platform. If you have any
                uncertainty regarding the authenticity of any communication or
                notice, please contact: and notify our team.
              </p>
              <h5 className="font-weight-bold">LIMITATION OF LIABILITY</h5>
              <p>
                <b>Play-Crypto</b>, as well as its officers, directors, agents,
                joint ventures, employees, suppliers and advisors, assumes no
                liability or responsibility for any loss raised from the token
                sale, arising out of or related to the use of the Play-Crypto
                platform or any technical, interruption or malfunction of the
                Play-Crypto platform, website, token, services or otherwise. In
                no event shall Play-Crypto, as well as its officers, directors,
                agents, joint ventures, employees, suppliers and advisors, be
                liable for any special, indirect, incidental, punitive or
                consequential damages of any kind whatsoever, including without
                limitation any damages caused by or resuming from reliance by
                any user or any information obtained from the Company, or that
                result from mistakes, omissions, interruptions, deletion of
                files or email, errors, defects, viruses, delays in operation or
                transmission or any failure of performance, whether or not
                resulting from a force majeure event, suspension of Ethereum
                network communication failure, theft, destruction or
                unauthorized access to company's records, services, website. In
                addition to the above, company shall not be liable for any loss
                of profit, loss of business, trading losses, loss in value, or
                any other loss. This applies even if such damages are
                foreseeable. The limitation of liability set out above shall not
                be applicable in the event that Play-Crypto, or a Play-Crypto
                employee, has caused the damage by intentional misconduct or by
                gross negligence. Each party acknowledges that the fees payable
                hereunder reflects the allocation of risk set forth in the
                agreement and that each party would not enter into the agreement
                without these limitations on liability.
              </p>
              <p>
                <b>INDEMNIFICATION</b>
              </p>
              <p>
                You will defend, indemnify and hold Company, its affiliates and
                licensors and its officers, directors, contractors, employees,
                agents and representatives, harmless from and against all
                claims, actions, demands, liabilities, damages, losses, costs
                and expenses (including legal fees) relating to or arising from
                (a) your use of our services, website, platform, Play-Crypto (b)
                your breach of these terms or any other liabilities arising out
                of your use of the services, website and product or the use by
                any other person accessing the website, service, platform,
                Play-Crypto using your computer and/or your personal information
                (c) your violation of any rights of any other person or entity
                in connection with these terms (d) third-party claims concerning
                these terms or you thereof. You will promptly notify us of any
                third-party claims, actions, or proceedings which you become
                aware of that involves company as a result of these terms.
                Company may participate with counsel of its own choosing in any
                proceedings describing above. Company will be required to
                respond to compulsory legal order, you will reimburse company
                for reasonable attorney and contractors fees for their time and
                material spent responding to obligatory legal orders. You will
                not agree to any settlement which imposes any obligation or
                liability for company without company's prior written consent.
              </p>
              <h5 className="font-weight-bold">FEEDBACK</h5>
              <p>
                In connection with your use of our services, website, platform,
                you may provide us with your feedback (suggestions, ideas,
                reviews, comments, material and questions or other information
                and/or material). We will own exclusive rights, including all
                intellectual property rights, to any feedback, suggestions,
                ideas or other information or materials you provide to us,
                whether by email, posting through our services, website,
                platform or otherwise. You waive any rights you may have to the
                feedback (including any copyrights to the extent permitted by
                law). And Feedback you provide to us is non-confidential,
                non-proprietary and is not subject to a third-party license and
                we have no liability for any loss or damage suffered by you as a
                result of the disclosure or use of such feedback. We have the
                right to disclose your identity to any third-party who is
                claiming that any content posted by you constitutes a violation
                of their intellectual property rights, or of their right to
                privacy. We have the right to remove any posting you make on our
                website if, in our opinion, your post does not comply with the
                content standards set out in this section.
              </p>
              <h5 className="font-weight-bold">TERM AND TERMINATION</h5>
              <p>
                This agreement is entered into for unlimited duration. We
                reserve the right to terminate and/or suspend your access to our
                services/website, without giving you prior written notice and
                delete all related information and files related to your use of
                our services, website, platform, mobile application, without
                liability to you, at any time for any reason, including, but not
                limited to, if based on our sole discretion believe: (i) You
                have violated these Terms, (ii) You create risk or possible
                legal exposure for us.
              </p>
              <h5 className="font-weight-bold">NOTICES</h5>
              <p>
                You agree and consent to receive electronically all
                communications, agreements, documents, receipts, notices and
                disclosures (collectively, “Communications”) that Company
                provides in connection with your use of Company Services,
                Website, Platform and mobile application. You acknowledge and
                agree that Company may provide these communications to you by
                posting them on its Website or by emailing them to you at the
                email address you provide. Any email communication will be
                considered to be received by you within 24 hours after we email
                it to you and Communication via our Website will be effective
                upon posting. You should maintain copies of electronic
                Communications by printing a paper copy or saving an electronic
                copy.It is your responsibility to keep your email address with
                the Company up to date so that the Company can communicate with
                you electronically. You understand and agree that if the Company
                sends you an electronic communication, but you do not receive it
                because your email address is incorrect, out of date, blocked by
                your service provider, or you are otherwise unable to receive
                electronic communications, Company will be deemed to have
                provided the communication to you. Please note that if you use a
                spam filter that blocks or re-routes emails from senders not
                listed in your email address book, you must add the Company to
                your email address book so that you will be able to receive the
                communications we send to you. You can update your information
                at any time by sending such information to: You may also contact
                us by sending an email to with general questions or for
                assistance. All legal notices, including legal disputes, must be
                sent in writing to the following address: or postage prepaid by
                certified or registered mail, return receipt requested and
                addressed to the authorized representative of the relevant
                Party. All given notices must be in English language.
              </p>
              <h5 className="font-weight-bold">SEVERABILITY</h5>
              <p>
                If any of the provisions of the Terms or of the Agreement are
                deemed to be invalid, void or unenforceable, the remaining
                provisions shall continue in full force and effect.
              </p>
              <h5 className="font-weight-bold">
                APPLICABLE LAW AND ARBITRATION
              </h5>
              <p>
                The Terms are subject to and governed by Estonian Law. Any User
                of Play-Crypto agrees to seek an amicable settlement prior to
                bringing any legal action. All disputes arising from or under
                these Terms shall be resolved by arbitration in accordance with
                The Court of Arbitration of the Estonian Chamber of Commerce and
                Industry on the date when the Notice of Arbitration is submitted
                in accordance with these Rules. The arbitration panel shall
                consist of one arbitrator only. The seat of the arbitration
                shall be Tallin, Estonia. The arbitral proceedings shall be
                conducted in English. In the event that U.S. court overrides the
                above section regarding applicable law and jurisdiction and
                determines to apply U.S. law. All suits shall be brought
                exclusively in the state of Delaware and applying Delaware state
                law.
                <br />
                <br />
                EXCEPT WHERE PROHIBITED BY APPLICABLE LAW, YOU AGREE THAT ANY
                CLAIM YOU MAY HAVE AGAINST US SHALL BE BROUGHT INDIVIDUALLY AND
                YOU SHALL NOT JOIN SUCH CLAIM WITH CLAIMS OF ANY OTHER PERSON OR
                ENTITY OR BRING, JOIN, OR PARTICIPATE IN A CLASS ACTION AGAINST
                US. YOU FURTHER AGREE TO EXPRESSLY WAIVE YOUR RIGHT TO A TRIAL
                BY JURY.ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF
                OR RELATING TO THESE TERMS OF USE OR THE SERVICES OR SITE MUST
                BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ACCRUES,
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </p>
              <h5 className="font-weight-bold">PRIVACY</h5>
              <p>
                <b>User Blockchain Wallets.</b>
              </p>
              <p>
                The company classifies users through the user-connected
                Blockchain wallet address for the convenience of the users'
                information protection and service information, and performs all
                the user management tasks in the service. The user shall manage
                his or her blockchain wallet address with his or her duty. The
                company shall not take any responsibility for damages caused by
                neglecting the information of the blockchain wallet or accepting
                the use of the information to a third-party, and all
                responsibilities shall be borne by the user. The company may
                refuse to use a blockchain wallet that is restricted to use.
              </p>
              <p>
                <b>Security Measures</b>
              </p>
              <p>
                We protect your information using physical, technical, and
                administrative security measures to reduce the risks of loss,
                misuse, unauthorized access, disclosure, and alteration. Some of
                the safeguards we use are firewalls and data encryption,
                physical access controls to our data centers, and information
                access authorization controls. We also authorize access to
                personal information only for those employees who require it to
                fulfill their job responsibilities.
              </p>
              <p>
                <b>Usage and Sharing.</b>
              </p>
              <p>
                We may create anonymous, aggregated and/or other de-identified
                data records (“anonymous Information”) from information that you
                provide to us by excluding information that makes the
                information personally identifiable to you. We use this
                anonymous information to analyze request and usage patterns so
                that we may enhance the content of our services and to improve
                site navigation. We reserve the right to use anonymous
                information for any purpose and to disclose such data to third
                parties in our discretion.
              </p>
              <p>
                <b>Sharing of Personal Data.</b>
              </p>
              <p>
                We take your privacy very seriously, and will only share your
                personal information as needed with the entities within the
                ecosystem to perform the services and in the event if we are
                legally to meet any legal, regulatory, or governmental request,
                including but not limited to events when we are compelled to do
                so by a subpoena, court order, or similar legal procedure.
              </p>

              <h5 className="font-weight-bold">MISCELLANEOUS</h5>
              <p>
                <b>Entire Agreement.</b>
              </p>
              <p>
                These terms represent the entire agreement, and supersede all
                prior and contemporaneous understandings between you and the
                Company regarding the subject matter, especially regarding the
                services, website, platform and CHAIN. In the event of any
                conflict between these terms and any other agreement you may
                have with the Company, the terms of that other agreement will
                prevail only if these terms are specifically identified and
                declared to be overridden by such other agreement. Assignment:
                These Terms shall be binding upon and inure to the benefit of
                the parties and their permitted successors and assigns. You may
                not assign or transfer any of your rights or obligations under
                these Terms without Company's prior written consent from,
                including by operation of law or in connection with any change
                of control. Company may assign or transfer any or all of its
                rights under these Terms, in whole or in part, without obtaining
                your prior written consent or approval.
              </p>
              <p>
                <b>Act of God Events.</b>
              </p>
              <p>
                Company shall not be liable for (1) any inaccuracy, error,
                delays or omission of (i) any information, or (ii) the
                transmission or delivery of information; (2) any loss or damage
                arising from pandemic, flood, extraordinary weather conditions,
                earthquake, or other act of God, fire, war, insurrection, riot,
                labor dispute, accident, action of government, communications,
                power failure, or equipment or software malfunction or any other
                cause beyond Company's reasonable control, also known as “Force
                Majeure Event”.
              </p>
              <p>
                <b>Waiver.</b>
              </p>
              <p>
                Company failure or delay in exercising any right, power or
                privilege under these Terms shall not operate as a waiver
                thereof.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="policy" tabIndex="-1" aria-labelledby="policy" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content round">
            <div className="modal-header">
              <h5 className="modal-title font-medium-3 font-weight-bold">Acceptance Policy</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                  <i className="fa-regular fa-rectangle-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="font-weight-bold">Privacy Policy</h5>
              <p>
                Play-Crypto operates the play-crypto.com website, which provides
                the service. This page is used to inform website visitors
                regarding our policies with the collection, use, and disclosure
                of Personal Information if anyone decided to use our Service.
              </p>
              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>
              <h5 className="font-weight-bold">
                Information Collection and Use
              </h5>
              <p>
                For a better experience, we may require you to provide us with
                certain personally identifiable information, including but not
                limited to your name, phone number, and postal address. The
                information that we collect will be used to contact or identify
                you.
              </p>
              <h5 className="font-weight-bold">Log Data</h5>
              <p>
                We want to inform you that whenever you visit our Service, we
                collect information that your browser sends to us that is called
                Log Data. This Log Data may include information such as your
                computer's Internet Protocol (“IP”) address, browser version,
                pages of our Service that you visit, the time and date of your
                visit, the time spent on those pages, and other statistics.
              </p>
              <h5 className="font-weight-bold">Cookies</h5>
              <p>
                Cookies are files with small amount of data that is commonly
                used as an anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                computer's hard drive.
              </p>
              <p>
                Our website uses these “cookies” to ensure certain website
                features function properly. This data is not mined, shared, or
                otherwise collected for commercial use. You are free to delete
                these cookies from within your web browser.
              </p>
              <p>
                You agree to the use of these cookies by visiting our website
                and using its features.
              </p>
              <h5 className="font-weight-bold">Service Providers</h5>
              <p>
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>
              <ul className="list-style-square">
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>
              <p>
                We want to inform our Service users that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>
              <h5 className="font-weight-bold">Security</h5>
              <p>
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>
              <h5 className="font-weight-bold">Links to Other Sites</h5>
              <p>
                Our Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>
              <h5 className="font-weight-bold">Children's Privacy</h5>
              <p>
                Our Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13. In the case we discover that a child under 13
                has provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal
                information, please contact us so that we will be able to do
                necessary actions.
              </p>
              <h5 className="font-weight-bold">
                Changes to This Privacy Policy
              </h5>
              <p>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </p>
              <h5 className="font-weight-bold">Contact Us</h5>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to{" "}
                <a href="/">
                  <span className="font-weight-bold">
                    <u>contact us</u>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer footer-transparent footer-light navbar-shadow">
        <div className="container">
          <div className="clearfix row blue-grey lighten-2 mb-0">
            <div className="col-md-6 p-0 justify-content-md-start justify-content-center">
              <p className="mb-0">Copyright &copy; 2023 Play-Crypto</p>
            </div>
            {/* <div className="col-md-6 p-0 mt-1 mt-md-0">
              <div className="d-flex justify-content-md-end justify-content-center">
                <p
                  data-toggle="modal"
                  data-target="#terms"
                  className="text-dark mb-0"
                  style={{ cursor: "pointer" }}
                >
                  Terms of use
                </p>
                &nbsp;|&nbsp;
                <p
                  data-toggle="modal"
                  data-target="#policy"
                  className="text-dark mb-0"
                  style={{ cursor: "pointer" }}
                >
                  Acceptance policy
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
