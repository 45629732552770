import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Login(props) {
  const { setUser, setToken, setIsLogin } = props.data;
  const navigate = useNavigate();
  useEffect(() => {
    let bodyTag = document.body;
    if (!bodyTag.classList.contains("1-column")) bodyTag.classList.add("1-column"); bodyTag.dataset.col = "1-column";
    if (!bodyTag.classList.contains("bg-full-screen-image")) bodyTag.classList.add("bg-full-screen-image");
    if (!bodyTag.classList.contains("blank-page")) bodyTag.classList.add("blank-page");
    if (bodyTag.dataset.col === "2-column") bodyTag.dataset.col = "1-column";
    if (bodyTag.classList.contains("2-columns")) bodyTag.classList.remove("2-columns");    
  },[]);
  
  const sendOtp = async () => {
    const email = document.getElementById('user-mobile');
    const emailError = document.getElementById('user-mobile-alert');

    if (!email.value) {
      email.classList.add('is-invalid');      
      emailError.classList.remove('d-none');
      emailError.innerHTML = 'Please enter your email id.';
      return false;
    }else{
      if(email.classList.contains("is-invalid")) email.classList.remove("is-invalid");      
    }
    
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "email": email.value })
    });
    response = await response.json();
  
    if(response.status === 'success'){
      if(email.classList.contains("is-invalid")) email.classList.remove("is-invalid");
      document.getElementById('userOtp').classList.remove("d-none");
      document.getElementById('resendOtp').classList.remove("d-none");
      let alert = document.getElementById('alertSuccess');
      alert.classList.remove("d-none");
      alert.innerHTML = "OTP sent successfully";
      setInterval(() => {
        alert.classList.add("d-none");
      }, 3000);
      document.getElementById('otpBtn').classList.add("d-none");
      document.getElementById('loginBtn').classList.remove("d-none");
    }else if(response.status === 'failed'){
      email.classList.add('is-invalid');
      let cellInput = document.getElementById('user-mobile-alert');
      cellInput.classList.remove('d-none');
      cellInput.innerHTML = response.error;
    }
  }
  
  const login = async () => {
    const email = document.getElementById('user-mobile');
    const emailError = document.getElementById('user-mobile-alert');
    const otp = document.getElementById("user-otp");
    const otpError = document.getElementById('user-otp-alert');

    if (!email.value) {
      email.classList.add('is-invalid');      
      emailError.classList.remove('d-none');
      emailError.innerHTML = 'Please enter your email id.';
      return false;
    }else{
      if(email.classList.contains("is-invalid")) email.classList.remove("is-invalid");
    }

    if (!otp.value) {
      otp.classList.add('is-invalid');      
      otpError.classList.remove('d-none');
      otpError.innerHTML = 'Please enter otp id.';
      return false;
    }else{
      if(otp.classList.contains("is-invalid")) otp.classList.remove("is-invalid");      
    }

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/otp-verify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "email": email.value,
        "otp": otp.value * 1
      })
    });
    response = await response.json();
  
    if(response.status === 'success'){    
      sessionStorage.setItem('token', response.data.tokan);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      setToken(response.data.tokan);
      setUser(response.data.user);
      setIsLogin(true);
      navigate("/dashboard");
    }else if(response.status === 'failed'){
      document.getElementById('user-otp').classList.add('is-invalid');
      let cellInput = document.getElementById('user-otp-alert');
      cellInput.classList.remove('d-none');
      cellInput.innerHTML = response.error;
    }  
  }

  return (
    <div className="app-content container center-layout mt-2">
      <div className="content-wrapper">
        <div className="content-body">
          <section className="row flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-6 col-md-8 col-11 p-0">
                <div className="card border-grey border-lighten-3 px-1 py-1 m-0 round">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <Link to={"/"}><img src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} className="auth-logo" alt="branding logo"/></Link>
                    </div>
                  </div>
                  <div className="card-content">
                    <p className="card-subtitle line-on-side text-muted text-center font-medium-3 mx-2 my-1">
                      <span>Login to Play-Crypto</span>
                    </p>
                    <p className="text-center font-medium-2 mt-2 mb-0 text-success d-none" id="alertSuccess"></p>
                    <div className="card-body">
                      <form className="form-horizontal" noValidate onSubmit={sendOtp}>
                        <fieldset className="form-group position-relative has-icon-left mb-1">
                          <input type="email" className="form-control font-medium-2" name="email" id="user-mobile" placeholder="Enter Email" required />
                          <div className="form-control-position"><i className="la la-envelope" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-mobile-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left d-none" id="userOtp">
                          <input type="number" className="form-control font-medium-2" name="otp" id="user-otp" placeholder="Enter OTP" required />
                          <div className="form-control-position"><i className="ft-message-square" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-otp-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <div className="form-group row d-none" id="resendOtp">
                          <div className="col-12 float-sm-left text-center text-sm-right">
                            <p className="info my-0 font-medium-2" style={{cursor: "pointer"}} onClick={sendOtp}>Resend OTP</p>
                          </div>
                        </div>
                        <button type="button" onClick={sendOtp} id="otpBtn" className="btn btn-info btn-block font-medium-1"><i className="ft-message-square" style={{fontSize:"1.5rem"}}></i> Get OTP</button>
                        <button type="button" onClick={login} id="loginBtn" className="btn btn-info btn-block d-none font-medium-1"><i className="la la-rocket" style={{fontSize:"1.5rem"}}></i> Login</button>
                      </form>
                    </div>
                    <p className="card-subtitle line-on-side text-muted text-center font-medium-3 mx-2 my-1">
                      <span>New to Play-Crpto ?</span>
                    </p>
                    <div className="card-body">
                      <Link to={"/register"} className="btn btn-outline-info btn-block font-medium-1"><i className="la la-user" style={{fontSize:"1.5rem"}}></i>Register</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
