import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer footer-transparent footer-light navbar-shadow">
        <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2 container center-layout">
          <span className="float-md-left d-block d-md-inline-block">Copyright &copy; 2023 Play-Crypto</span>
          <span className="float-md-right d-none d-lg-block">Ver 1.0<span id="scroll-top"></span></span>          
        </p>
      </footer>
      <div className="mobile-menu d-flex fixed-bottom d-md-none bg-white font shadow">
        <div className="col">
          <Link to={"/dashboard"} className="text-center">
            <p className="mb-0"><i className="la la-home"></i></p>
            <p className="mb-0 black font-weight-bold">Home</p>
          </Link>
        </div>
        <div className="col">
          <Link to={"/mybids"} className="text-center">
            <p className="mb-0"><i className="la la-gamepad"></i></p>
            <p className="mb-0 black font-weight-bold">Bids</p>
          </Link>
        </div>        
        <div className="col">
          <Link to={"/wallet"} className="text-center">
            <p className="mb-0"><i className="icon-wallet"></i></p>
            <p className="mb-0 black font-weight-bold">Wallet</p>
          </Link>
        </div>
        <div className="col">
          <Link to={"/profile"} className="text-center">
            <p className="mb-0"><i className="la la-user"></i></p>
            <p className="mb-0 black font-weight-bold">User</p>
          </Link>
        </div>
      </div>
    </>
  );
}
