import React, { useEffect, useRef, useState } from "react";
let tvScriptLoadingPromise;

function Chart(props) {
  const { trade, liveShow } = props.data;  
  const onLoadScriptRef = useRef();
  const [coin, setCoin] = useState(0);
  const [oldBtc, setOldBtc] = useState(0);

  useEffect(() => {
    let btcPrice = document.getElementById("btcprice");
    btcPrice.style.color = oldBtc > coin ? "#ff4961" : "#28d094";
    setOldBtc(coin);
  }, [coin]);

  useEffect(() => {
    if (liveShow) {
      trade.onmessage = function (msg) {
        var data = JSON.parse(msg.data);
        setCoin(data.bitcoin);
      };      
    }

    onLoadScriptRef.current = createWidget;
    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;
        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_04b47") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          autosize: true,
          symbol: "BITSTAMP:BTCUSD",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          hide_top_toolbar: true,
          hide_legend: true,
          save_image: false,
          container_id: "tradingview_04b47",
        });
      }
    }
  }, []);

  return (
    <div className="tradingview-widget-container justify-content-center" style={{ display: "contents" }}>
      <div id="tradingview_04b47" />
      <div className={`mx-auto mt-2 ${liveShow ? 'd-block' : 'd-none' }`}>
        <h4 className="gray"> Bitcoin Live : <span id="btcprice">${coin}</span></h4>
      </div>
    </div>
  );
}

export default Chart;
