import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Game from "../components/page/Game";
import Chart from "../components/page/Chart";
import Pagination from "../components/page/Pagination";

function Dashboard(props) {
  const { socket, logout, joinGame, setJoinGame, tradeWs } = props.data;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const [games, setGames] = useState({});
  const [bitcoin, setBitcoin] = useState(0);
  const [coin, setCoin] = useState(0);
  const [oldBtc, setOldBtc] = useState(0);
  const [userBids, setUserBids] = useState({});
  const [bidInfo, setBidInfo] = useState({});
  const [pagination, setPagination] = useState("");
  const [topGame, setTopGame] = useState({ amt: 0, name: "", show: false, time: "" });

  const fetchGame = async (pageId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/dashboard?page=${pageId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setGames(json.data.games);
        setUserBids(json.data.userBids);
        setPagination(json.data.pagination);
        sessionStorage.setItem("user", JSON.stringify(json.data.user));
      }
    }
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const fullDate = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", hour12: false, minute: "2-digit", second: "2-digit" });
  };

  const btcVal = (amt) => {
    var newBtc = amt / bitcoin;
    return newBtc > 0 ? newBtc.toFixed(5) : 0;
  };

  const handleBidInfo = async (bidId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/bid/${bidId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        // json.data.bid.btc_price = JSON.parse(json.data.bid.btc_price);
        setBidInfo(json.data);
      }
    }
  };

  const showTopGame = () => {
    setTopGame({ amt: 0, name: "", show: false, time: "" });
    if (games.length > 0) {
      let topGame;
      games.map((game) => {
        if (game.name == 'Mega Game') topGame = game;
      });

      if (topGame.pot_amount > 0) {
        setTopGame({ amt: topGame.pot_amount, name: topGame.name, show: true });
      }
    }
  }

  useEffect(() => {
    fetchGame(1);
    // eslint-disable-next-line
    tradeWs.onmessage = function (msg) {
      var data = JSON.parse(msg.data);
      setCoin(data.bitcoin);
      setBitcoin(data.bitcoin);
    };
  }, []);

  useEffect(() => { showTopGame(); }, [games]);
  useEffect(() => {
    let btcPrice = document.getElementById('btcPriceDash');
    btcPrice.style.color = oldBtc > coin ? "#ff4961" : "#28d094";
    setOldBtc(coin);
  }, [coin]);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <div className="row">
            <div className={`${topGame.show ? 'col-lg-6' : 'col-12'}`}>
              <div className="card round">
                <div className="card-body">
                  <Chart data={{ trade: tradeWs, liveShow: false }} />
                </div>
              </div>
            </div>
            {topGame.show && <div className="col-lg-6" style={{ paddingBottom: "25px" }}>
              <div className="box">
                <div className="pot-amount">
                  <h4 className="mb-1">YOU MAY WiN BIG</h4>
                  <div className="d-flex">
                    <div className="amt mb-1">
                      <div className="amt-inner">
                        <h4 className="m-0">${topGame.amt}</h4>
                      </div>
                    </div>
                  </div>
                  <p className="m-0">Remaining Time: {topGame.time}</p>
                  <h4 className="m-0">Play <span className="text-success">Mega Game</span> Now</h4>
                </div>
              </div>
            </div>}
          </div>
          <section className="mb-1 mt-2">
            <div className="row">
              <div className="col-lg-6 mb-2">
                <div className="card-content">
                  <div className="media align-items-stretch bg-light text-white round">
                    <div className="bg-secondary p-1 media-middle round">
                      <i className="la la-btc font-large-3 text-white "></i>
                    </div>
                    <div className="media-body px-1 px-sm-2 py-1">
                      <h4 className="font-weight-bold font-large-1 mb-0">Bitcoin</h4>
                      <span className="black font-medium-2">Live Rate</span>
                    </div>
                    <div className="media-right p-0 p-sm-2 mr-1 mr-sm-0 media-middle my-auto">
                      <h1 className="font-weight-bold font-large-1 my-auto" id="btcPriceDash">${coin}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <Link to={"/wallet"}>
                  <div className="card-content">
                    <div className="media align-items-stretch text-white round bg-wallet">
                      <div className="p-2 media-middle my-auto">
                        <h1 className="text-white font-weight-bold font-large-1 my-auto">${user.wallet}</h1>
                      </div>
                      <div className="media-body px-2 py-1">
                        <h4 className="text-white font-weight-bold font-large-1 mb-0">Wallet</h4>
                        <span className="font-medium-2">Add Money</span>
                      </div>
                      <div className="media-right bg-dark-wallet p-2 media-middle round">
                        <i className="icon-wallet font-large-2 text-white"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section className="mb-3">
            <h4 className="gray font-large-1 headingBorder"><b>Let’s Start The Game</b></h4>
          </section>
          <section>
            <div className="row mt-2 justify-content-around">
              {games.length > 0 &&
                games.map((game) => {
                  return <Game key={game.gid} data={{ socket, joinGame, setJoinGame, game, bitcoin, fetchGame, setTopGame }} />;
                })
              }
            </div>
          </section>
          <section className="mb-3 today-bid">
            <h4 className="gray font-large-1 headingBorder">
              <b>Played in the last 24 hours</b>
              <Link to={"/mybids"} className="btn btn-sm round btn-info font-medium-3 ml-1">All Bids</Link>
            </h4>
            <div className="my-3">
              {userBids.length > 0 &&
                userBids.map((userBid) => {
                  return (
                    <div className="card card-body round mb-1" key={userBid._id}>
                      <div className="tableCard">
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Game</h4>
                          <h5>{userBid.game.name}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Game ID</h4>
                          <h5>#{userBid.gid}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Time</h4>
                          <h5>{dateFormat(userBid.game.start_time)} - {dateFormat(userBid.game.end_time)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Status</h4>
                          {(userBid.status === "pending" || userBid.status === "running") && <h5 className="round badge badge-primary font-medium-2 ucfirst">{userBid.status}</h5>}
                          {(userBid.status === "win" || userBid.status === "sorry") && <h5 className={`round badge badge-${userBid.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{userBid.status}</h5>}
                        </div>
                        <div className="px-1">
                          <h4 className="font-weight-bold">Bid Amount</h4>
                          <h6 className="mb-0">₿{btcVal(userBid.amount)}</h6>
                          <h6 className="mb-0">${userBid.amount}</h6>
                        </div>
                        <div className="my-auto">
                          <button className="btn btn-sm round btn-info font-medium-1" onClick={() => handleBidInfo(userBid._id)} data-toggle="modal" data-target="#bidInfoModal">More info</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {!userBids.length &&
                <div className="card card-body round mb-1">
                  <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
                </div>
              }
              <div className="mb-3 mt-2">
                <Pagination data={{ pagination, fetchTran: fetchGame }} />
              </div>
            </div>
          </section>
          <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="round modal-content">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Bid Info</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="fa-regular fa-rectangle-xmark"></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="table-responsive">
                    <table className="table mb-0 table-bordered">
                      <tbody className="font-medium-3">
                        <tr>
                          <td><b>Date Time</b></td>
                          <td>{fullDate(bidInfo?.bid?.created_at)}</td>
                        </tr>
                        <tr>
                          <td><b>Game Id</b></td>
                          <td>#{bidInfo?.bid?.gid}</td>
                        </tr>
                        <tr>
                          <td><b>Picked Number</b></td>
                          <td>
                            <span className="round badge badge-info">{bidInfo?.bid?.btc_range}</span>
                            {/* {bidInfo?.bid?.btc_price.length > 0 && 
                              bidInfo?.bid?.btc_price.map((picked, inx) => {
                                return <span key={inx+picked} className="round badge badge-info mr-1">{picked}</span>
                              })
                            } */}
                          </td>
                        </tr>
                        <tr>
                          <td><b>Status</b></td>
                          <td className="ucfirst">
                            {(bidInfo?.bid?.status === "pending" || bidInfo?.bid?.status === "running") && <h5 className="mb-0 round badge badge-primary font-medium-2 ucfirst">{bidInfo?.bid?.status}</h5>}
                            {(bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry") && <h5 className={`mb-0 round badge badge-${bidInfo?.bid?.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{bidInfo?.bid?.status}</h5>}
                          </td>
                        </tr>
                        <tr>
                          <td><b>Winning Number</b></td>
                          <td><span className="badge badge-success font-medium-2 round">{bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry" ? bidInfo?.game?.winning_number : ''}</span></td>
                        </tr>
                        <tr>
                          <td><b>Winning Amount</b></td>
                          <td><span className={`text-${bidInfo?.bid?.win_amount && 'success'}`}>${bidInfo?.bid?.win_amount}</span></td>
                        </tr>
                        <tr>
                          <td><b>Bid Units</b></td>
                          <td><span className="badge badge-info font-medium-2 round">{bidInfo?.bid?.unit}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
