import React, { useEffect } from "react";

function Test() {
  useEffect(() => {    
    let fire = document.getElementById("pyro");    
    fire.style.display = "block";
    setTimeout(() => {
      let fire = document.getElementById("pyro");
      fire.style.display = "none";  
    }, 5000);
  },[]);
  
  return (
    <>
      <h1 className="text-center my-5">This is a test page.</h1>
    </>
  );
}

export default Test;
