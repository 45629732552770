import React, { useState, useEffect } from 'react';

const TimerSec = (props) => {
  const { gameData } = props.data;
  const first = new Date().getTime();
  const two = new Date(gameData?.lastGame?.draw_time);
  const [different, setDifferent] = useState(Math.floor((two - first) / 1000));
  const [seconds, setSeconds] = useState(different);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => { setSeconds(prevSeconds => prevSeconds - 1); }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds]);

  useEffect(() => {
    const first = new Date().getTime();
    const two = new Date(gameData?.lastGame?.draw_time);
    setDifferent(Math.floor((two - first) / 1000));
  }, [gameData]);

  if (seconds > 0) {
    return <>Results for game #{gameData?.lastGame?.gid} in <span className="round text-white" style={{ backgroundColor: gameData.colour, padding: "5px", paddingLeft: "10px", paddingRight: "10px" }}>{seconds}</span> seconds</>;
  }
  if (gameData?.lastGame?.winning_number) {
    return <><span className="round text-white" style={{ backgroundColor: gameData.colour, padding: "5px", paddingLeft: "10px", paddingRight: "10px" }}>{gameData?.lastGame?.winning_number}</span> Is the winning number for game #{gameData?.lastGame?.gid}.</>;
  }
  return "";
};

export default TimerSec;
