import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Register(props) {
  const { setUser, setToken, setIsLogin } = props.data;
  const navigate = useNavigate();
  useEffect(() => {
    let bodyTag = document.body;
    if(!bodyTag.classList.contains('1-column')) bodyTag.classList.add('1-column'); bodyTag.dataset.col = "1-column";
    if(!bodyTag.classList.contains('bg-full-screen-image')) bodyTag.classList.add('bg-full-screen-image');
    if(!bodyTag.classList.contains('blank-page')) bodyTag.classList.add('blank-page');
    if (bodyTag.dataset.col === "2-column") bodyTag.dataset.col = "1-column";
    if (bodyTag.classList.contains("2-columns")) bodyTag.classList.remove("2-columns");
  },[]);

  const sendOtp = async () => {
    const terms = document.getElementById("terms");
    const name = document.getElementById("user-name");
    const email = document.getElementById("user-mobile");
    
    if(!name.value){
      name.classList.add('is-invalid');
      let nameInput = document.getElementById('user-name-alert');
      nameInput.classList.remove('d-none');
      nameInput.innerHTML = 'Please enter the name.';
    }else{
      name.classList.remove("is-invalid");
      document.getElementById('user-name-alert').classList.add("d-none");
    }

    if(!email.value){
      email.classList.add('is-invalid');
      let emailInput = document.getElementById('user-mobile-alert');
      emailInput.classList.remove('d-none');
      emailInput.innerHTML = 'Please enter the email.';
    }else{
      email.classList.remove("is-invalid");
      document.getElementById('user-mobile-alert').classList.add("d-none");
    }

    if(terms.checked === false){
      toast.warning(
        <h5 className="font-weight-bold">Please accept terms of service.</h5>,
        {
          position: "bottom-center",
          autoClose: 3000,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return false
    }

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "name": name.value,        
        "email": email.value
      })
    });
    response = await response.json();
  
    if(response.status === 'success'){
      if(document.getElementById('user-mobile').classList.contains("is-invalid")){
        document.getElementById('user-mobile').classList.remove("is-invalid");
      }    
      document.getElementById('userOtp').classList.remove("d-none");
      document.getElementById('resendOtp').classList.remove("d-none");
      let alert = document.getElementById('alertSuccess');
      alert.classList.remove("d-none");
      alert.innerHTML = "OTP sent successfully";
      setInterval(() => {
        alert.classList.add("d-none");
      }, 3000);
      document.getElementById('otpBtn').classList.add("d-none");
      document.getElementById('loginBtn').classList.remove("d-none");
    }else if(response.status === 'failed'){
      document.getElementById('user-mobile').classList.add('is-invalid');
      let cellInput = document.getElementById('user-mobile-alert');
      cellInput.classList.remove('d-none');
      cellInput.innerHTML = response.error;
    }
  }

  const resend = async () => {
    let terms = document.getElementById("terms");
    if(terms.checked === false){
      toast.warning(
        <h5 className="font-weight-bold">Please accept terms of service.</h5>,
        {
          position: "bottom-center",
          autoClose: 3000,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return false
    }

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "name": document.getElementById("user-name").value,        
        "email": document.getElementById("user-mobile").value
      })
    });
    response = await response.json();
  
    if(response.status === 'success'){
      if(document.getElementById('user-mobile').classList.contains("is-invalid")){
        document.getElementById('user-mobile').classList.remove("is-invalid");
      }    
      document.getElementById('userOtp').classList.remove("d-none");
      document.getElementById('resendOtp').classList.remove("d-none");
      let alert = document.getElementById('alertSuccess');
      alert.classList.remove("d-none");
      alert.innerHTML = "OTP sent successfully";
      setInterval(() => {
        alert.classList.add("d-none");
      }, 3000);
      document.getElementById('otpBtn').classList.add("d-none");
      document.getElementById('loginBtn').classList.remove("d-none");
    }else if(response.status === 'failed'){
      document.getElementById('user-mobile').classList.add('is-invalid');
      let cellInput = document.getElementById('user-mobile-alert');
      cellInput.classList.remove('d-none');
      cellInput.innerHTML = response.error;
    }
  }

  const register = async () => {
    let terms = document.getElementById("terms");
    if(terms.checked === false){
      toast.warning(
        <h5 className="font-weight-bold">Please accept terms of service.</h5>,
        {
          position: "bottom-center",
          autoClose: 3000,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return false
    }
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/auth/otp-verify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "email": document.getElementById("user-mobile").value,
        "otp": document.getElementById("user-otp").value * 1
      })
    });
    response = await response.json();
  
    if(response.status === 'success'){    
      sessionStorage.setItem('token', response.data.tokan);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      setToken(response.data.tokan);
      setUser(response.data.user);
      setIsLogin(true);
      navigate("/dashboard");
    }else if(response.status === 'failed'){
      document.getElementById('user-otp').classList.add('is-invalid');
      let cellInput = document.getElementById('user-otp-alert');
      cellInput.classList.remove('d-none');
      cellInput.innerHTML = response.error;
    } 
  }

  return (
    <div className="app-content container center-layout mt-2">      
      <div className="content-wrapper">        
        <div className="content-body">
          <section className="row flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-6 col-md-8 col-10 p-0">
                <div className="card border-grey border-lighten-3 px-1 py-1 m-0 round">
                  <div className="card-header border-0 p-0">
                    <div className="card-title text-center">
                      <Link to={"/"}><img src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} className="auth-logo" alt="branding logo" /></Link>
                    </div>
                  </div>
                  <div className="card-content">                    
                    <p className="card-subtitle line-on-side text-muted text-center font-medium-3 mx-2 my-1">
                      <span>Register to Play-Crypto</span>
                    </p>
                    <p className="text-center font-medium-1 mt-2 mb-0 text-success d-none" id="alertSuccess"></p>
                    <div className="card-body">
                      <form className="form-horizontal"  noValidate onSubmit={sendOtp}>
                        <fieldset className="form-group position-relative has-icon-left mb-1">
                          <input type="text" className="form-control font-medium-2" name="name" id="user-name" placeholder="Enter Name" required data-validation-required-message=''/>
                          <div className="form-control-position"><i className="la la-user" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-name-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left mb-1">
                          <input type="email" className="form-control font-medium-2" name="cell" id="user-mobile" placeholder="Enter Email" required data-validation-required-message='' />
                          <div className="form-control-position"><i className="la la-envelope" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-mobile-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left  mb-1 d-none" id="userOtp">
                          <input type="number" min={100000} className="form-control font-medium-2" name="otp" id="user-otp" placeholder="Enter OTP" required />
                          <div className="form-control-position"><i className="ft-message-square" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-otp-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <div className="form-group row mb-1 d-none" id="resendOtp">
                          <div className="col-12 float-sm-left text-center text-sm-right">
                            <p className="info my-0 font-medium-2" style={{cursor: "pointer"}} onClick={resend}>Resend OTP</p>
                          </div>
                        </div>
                        <fieldset className="form-group mb-1 d-flex">
                          <input type="checkbox" id="terms" className="mr-1 my-auto" style={{height:"30px", width:"30px"}}/>
                          <label className="font-medium-2 mb-0" htmlFor="terms">Agree to accept the terms of service and opt-in to receive the messaging notification via Email.</label>
                        </fieldset>
                        <button type="button" onClick={sendOtp} id="otpBtn" className="btn btn-info btn-block font-medium-2"><i className="la la-rocket" style={{fontSize:"1.5rem"}}></i> Register</button>
                        <button type="button" onClick={register} id="loginBtn" className="btn btn-info btn-block d-none font-medium-2"><i className="ft-message-square" style={{fontSize:"1.5rem"}}></i> Verify OTP</button>
                      </form>
                    </div>
                    <p className="card-subtitle line-on-side text-muted text-center font-medium-3 mx-2 my-1">
                      <span>Member of Play-Crpto ?</span>
                    </p>
                    <div className="card-body">
                      <Link to={"/login"} className="btn btn-outline-info btn-block font-medium-2"><i className="la la-user" style={{fontSize:"1.5rem"}}></i>Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
