import React, { useEffect, useState } from "react";

const Timer = (props) => {
  const { gameData, fetchGame, setTopGame } = props.data;
  const end_time = new Date(gameData.end_time);
  const [remainingTime, setRemainingTime] = useState("");
  const [color, setColor] = useState("gray");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = end_time - now;
      if (distance < 0) {
        // setTimeout(() => {
        //   fetchGame(1);
        // }, 15000);
        clearInterval(interval);
      } else {
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setColor(hours === 0 && minutes === 0 && seconds <= 9 ? "danger" : "gray");
        setRemainingTime(`${hours}h:${minutes}m:${seconds}s`);
      }
    }, 1000);

    return () => { clearInterval(interval); };
  }, [end_time]);
  useEffect(() => {
    setTopGame((prev) => ({ ...prev, time: remainingTime }));
  }, [remainingTime])

  return <h4 className={`mb-0 ${color}`}>{remainingTime}</h4>;
};

export default Timer;
